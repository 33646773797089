<template>
  <div class="page-title">
    <h1>Lebenslauf</h1>
  </div>

  <article class="page type-page">
    <div class="entry-content">
      <div class="row">
        <div class="col-xs-12 col-sm-7">
          <div class="block-title">
            <h2>Ausbildung</h2>
          </div>

          <div class="timeline clearfix">
            <div class="timeline-item clearfix">
              <h5 class="item-period highlighted">2013</h5>
              <span class="item-company">Hochschule Lausitz</span>
              <h4 class="item-title">Maschinenbau B.Eng.</h4>
              <p>
                Nach kurzer Orientierungsphase im Studiengang
                Wirtschaftsingenieurwesen, entschloss ich mich nach dem
                2.Semester für den Studiengang Maschinenbau und schloss diesen
                2013 erfolgreich ab.
              </p>
            </div>

            <div class="timeline-item clearfix">
              <h5 class="item-period">2006</h5>
              <span class="item-company">Oberstufenzentrum Cottbus</span>
              <h4 class="item-title">Fachhochschulreife Technik</h4>
              <p>
                Nach kurzer beruflicher Tätigkeit als Elektroinstallateur,
                folgte die Qualifikation für die Fachhochschule mit der
                Fachhochschulreife Technik.
              </p>
            </div>

            <div class="timeline-item clearfix">
              <h5 class="item-period">2003</h5>
              <span class="item-company">Elektroinstallation W. Eggert</span>
              <h4 class="item-title">Elektroinstallateur</h4>
              <p>
                Die Berufsausbildung zum Elektroinstallateur mit erfolgreicher
                Gesellenprüfung der Handwerkskammer Cottbus, legte das
                technische Fundament.
              </p>
            </div>
          </div>

          <div class="block-title">
            <h2>Erfahrungen</h2>
          </div>

          <div class="timeline clearfix">
            <div class="timeline-item clearfix">
              <h5 class="item-period">ab 09.2022</h5>
              <span class="item-company"><a href="https://www.itgain-consulting.de/">ITGAIN Consulting Gesellschaft für
                  IT-Beratung mbH</a></span>
              <h4 class="item-title">Consultant (Software Engineering)</h4>
              <p>
                Entwicklung und Beratung in dem Bereich digitaler Lösungen für
                die Welt von morgen!
              </p>
            </div>
            <div class="timeline-item clearfix">
              <h5 class="item-period highlighted">12.2019 - 03.2022</h5>
              <span class="item-company">Gubener Plastinate GmbH</span>
              <h4 class="item-title">
                Bereichsleiter Innovationsmanagement und Projekte
              </h4>
              <p>
                Erschließung neuer digitaler Möglichkeiten für das Unternehmen
                in den Bereichen Vertrieb, Marketing und Produktportfolio.
                Umsetzung der gefundenen und wirtschaftlich sinnvollen
                Möglichkeiten mit den entsprechenden Fachabteilungen.
                Unterschiedliche Entwicklungen und Programmiertätigkeiten in den
                Bereichen Front- und Backend für öffentliche und interne
                Applikationen.
              </p>
            </div>

            <div class="timeline-item clearfix">
              <h5 class="item-period">02.2019 - 11.2019</h5>
              <span class="item-company">Auto-Kabel Management GmbH</span>
              <h4 class="item-title">Team Leader Innovation Center</h4>
              <p>
                Leitung eines Teams für die Entwicklung von Produkten und
                Produktionsprozessen für die Elektromobilität.
                Produktionsprozesse mit Schwerpunkt Fügetechnik und
                Qualitätssicherung mittels Künstlicher Intelligenz.
              </p>
            </div>

            <div class="timeline-item clearfix">
              <h5 class="item-period">10.2014 - 01.2019</h5>
              <span class="item-company">Auto-Kabel Management GmbH</span>
              <h4 class="item-title">Manufacturing Engineer (Expert)</h4>
              <p>
                Freigabe, Betreuung und Optimierung von Maschinen und
                Produktionsprozessen mit dem Schwerpunkt Fügetechnik.
              </p>
            </div>

            <div class="timeline-item clearfix">
              <h5 class="item-period">03.2013 - 05.2013</h5>
              <span class="item-company">Dürr Systems GmbH / R&amp;D Projects</span>
              <h4 class="item-title">Bachelorarbeit</h4>
              <p>
                Untersuchungen zur Karossenumströmung in einem modifizierten
                Technikumstauchbecken
              </p>
            </div>

            <div class="timeline-item clearfix">
              <h5 class="item-period">09.2012 - 02.2013</h5>
              <span class="item-company">Dürr Systems GmbH / R&amp;D Projects</span>
              <h4 class="item-title">Praktikum</h4>
              <ul>
                <li>
                  Kalibrierung von Messsystemen in einer Spritzkabine und
                  Inbetriebnahme dieser Messsysteme.
                </li>
                <li>
                  Auswahl eines geeigneten Fluidisierungsboden in Hinsicht auf
                  Druckverlust und Fluidisierungsfähigkeit für eine
                  Wirbelschichtverbrennungsanlage und Erstellung einer einfachen
                  Testanlage zur Aufnahme der Druckverluste.
                </li>
                <li>
                  Auslegung und Konstruktion einer
                  Wirbelschichtverbrennungs-Testanlage im Maßstab 1:10.
                </li>
                <li>
                  Optimierung einer Konstruktion zur Skidreinigung
                  (Karossenträgersystem).
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-5">
          <div class="block-title">
            <h2>Backend-Entwicklung</h2>
          </div>

          <div id="skills_1" class="skills-info skills-first-style">
            <div class="clearfix">
              <h4>PHP</h4>
              <div class="skill-value">80%</div>
            </div>
            <div id="skill_2" data-value="80" class="skill-container">
              <div class="skill-percentage" />
            </div>

            <div class="clearfix">
              <h4>mySQL</h4>
              <div class="skill-value">75%</div>
            </div>
            <div id="skill_1" data-value="75" class="skill-container">
              <div class="skill-percentage" />
            </div>

            <div class="clearfix">
              <h4>REST-API</h4>
              <div class="skill-value">75%</div>
            </div>
            <div id="skill_3" data-value="75" class="skill-container">
              <div class="skill-percentage" />
            </div>

            <div class="clearfix">
              <h4>Framework Laravel</h4>
              <div class="skill-value">85%</div>
            </div>
            <div id="skill_4" data-value="85" class="skill-container">
              <div class="skill-percentage" />
            </div>

            <div class="clearfix">
              <h4>AWS</h4>
              <div class="skill-value">65%</div>
            </div>
            <div id="skill_3" data-value="65" class="skill-container">
              <div class="skill-percentage" />
            </div>
          </div>

          <!-- Add White Space -->
          <div class="p-20" />
          <!-- /Add White Space -->

          <div class="block-title element-title">
            <h2>Frontend-Entwicklung</h2>
          </div>

          <div id="skills_2" class="skills-info skills-first-style">
            <div class="clearfix">
              <h4>JavaScript</h4>
              <div class="skill-value">80%</div>
            </div>
            <div id="skill_5" data-value="80" class="skill-container">
              <div class="skill-percentage" />
            </div>

            <div class="clearfix">
              <h4>Framework Vue.js</h4>
              <div class="skill-value">80%</div>
            </div>
            <div id="skill_6" data-value="80" class="skill-container">
              <div class="skill-percentage" />
            </div>

            <div class="clearfix">
              <h4>CSS Bootstrap</h4>
              <div class="skill-value">75%</div>
            </div>
            <div id="skill_7" data-value="75" class="skill-container">
              <div class="skill-percentage" />
            </div>

            <div class="clearfix">
              <h4>CSS Tailwind</h4>
              <div class="skill-value">75%</div>
            </div>
            <div id="skill_8" data-value="75" class="skill-container">
              <div class="skill-percentage" />
            </div>

            <div class="clearfix">
              <h4>Nuxt</h4>
              <div class="skill-value">70%</div>
            </div>
            <div id="skill_9" data-value="70" class="skill-container">
              <div class="skill-percentage" />
            </div>
          </div>

          <!-- Add White Space -->
          <!-- <div class="p-20"></div> -->
          <!-- /Add White Space -->

          <!-- <div class="block-title">
            <h2>Knowledges</h2>
          </div>

          <ul class="knowledges">
            <li>Marketing</li>
            <li>Print</li>
            <li>Digital Design</li>
            <li>Social Media</li>
            <li>Time Management</li>
            <li>Communication</li>
            <li>Problem-Solving</li>
            <li>Social Networking</li>
            <li>Flexibility</li>
          </ul> -->

          <!-- Add White Space -->
          <div class="p-50" />
          <!-- /Add White Space -->

          <div class="block-title">
            <h2>Zertifikate</h2>
          </div>

          <a href="./img/certificate/AWS–dein-weg-zum-zertifizierten-amazon-solutions-architekt-001.webp"
            class="lightbox">
            <div class="certificate-item clearfix">
              <div class="certi-logo">
                <img src="@/assets/svg/udemy-logo-001.svg" alt="logo" />
              </div>
              <div class="certi-content">
                <div class="certi-title">
                  <h4>AWS – Solutions Architekt</h4>
                </div>
                <div class="certi-id">
                  <span>Bescheinigung Nr.: UC-S4JGRAIR</span>
                </div>
                <div class="certi-date">
                  <span>25 Oktober 2019</span>
                </div>
                <div class="certi-company">
                  <span />
                </div>
              </div>
            </div>
          </a>

          <a href="./img/certificate/machine-learning-for-data-science-using-MATLAB-001.webp" class="lightbox">
            <div class="certificate-item clearfix">
              <div class="certi-logo">
                <img src="@/assets/svg/udemy-logo-001.svg" alt="logo" />
              </div>
              <div class="certi-content">
                <div class="certi-title">
                  <h4>Machine Learning for Data Science using MATLAB</h4>
                </div>
                <div class="certi-id">
                  <span>Bescheinigung Nr.: UC-S7M8WK23</span>
                </div>
                <div class="certi-date">
                  <span>25 Oktober 2019</span>
                </div>
                <div class="certi-company">
                  <span />
                </div>
              </div>
            </div>
          </a>

          <a href="./img/certificate/math-works-ml-001.webp" class="lightbox">
            <div class="certificate-item clearfix">
              <div class="certi-logo">
                <img src="@/assets/svg/logo-mathworks-001.svg" alt="logo" />
              </div>

              <div class="certi-content">
                <div class="certi-title">
                  <h4>Maschinelles Lernen mit MATLAB</h4>
                </div>
                <!-- <div class="certi-id">
                  <span>Membership ID: XXXX</span>
                </div> -->
                <div class="certi-date">
                  <span>4 - 5 Juli 2019</span>
                </div>
              </div>
            </div>
          </a>

          <a href="./img/certificate/math-works-statistische-methoden-001.webp" class="lightbox">
            <div class="certificate-item clearfix">
              <div class="certi-logo">
                <img src="@/assets/svg/logo-mathworks-001.svg" alt="logo" />
              </div>

              <div class="certi-content">
                <div class="certi-title">
                  <h4>Statistische Methoden in MATLAB</h4>
                </div>
                <!-- <div class="certi-id">
                  <span>Membership ID: XXXX</span>
                </div> -->
                <div class="certi-date">
                  <span>16 - 17 Mai 2019</span>
                </div>
              </div>
            </div>
          </a>

          <a href="./img/certificate/math-works-data-processing-and-visualization-001.webp" class="lightbox">
            <div class="certificate-item clearfix">
              <div class="certi-logo">
                <img src="@/assets/svg/logo-mathworks-001.svg" alt="logo" />
              </div>
              <div class="certi-content">
                <div class="certi-title">
                  <h4>Datenverarbeitung und Visualisierung mit MATLAB</h4>
                </div>
                <!-- <div class="certi-id">
                  <span>Membership ID: XXXX</span>
                </div> -->
                <div class="certi-date">
                  <span>29 November 2018</span>
                </div>
                <div class="certi-company">
                  <span />
                </div>
              </div>
            </div>
          </a>

          <a href="./img/certificate/big-data-analytics-001.webp" class="lightbox">
            <div class="certificate-item clearfix">
              <div class="certi-logo">
                <img src="@/assets/svg/hpi-logo-001.svg" alt="logo" />
              </div>
              <div class="certi-content">
                <div class="certi-title">
                  <h4>Big Data Analytics</h4>
                </div>
                <!-- <div class="certi-id">
                  <span>Membership ID: XXXX</span>
                </div> -->
                <div class="certi-date">
                  <span>8 Januar 2018</span>
                </div>
                <div class="certi-company">
                  <span />
                </div>
              </div>
            </div>
          </a>

          <a href="./img/certificate/acatec-ml-001.webp" class="lightbox">
            <div class="certificate-item clearfix">
              <div class="certi-logo">
                <img src="@/assets/svg/acatech-logo-001.svg" alt="logo" />
              </div>
              <div class="certi-content">
                <div class="certi-title">
                  <h4>Maschinelles Lernen</h4>
                </div>
                <!-- <div class="certi-id">
                  <span>Membership ID: XXXX</span>
                </div> -->
                <div class="certi-date">
                  <span>10 April 2017</span>
                </div>
                <div class="certi-company">
                  <span />
                </div>
              </div>
            </div>
          </a>

          <a href="./img/certificate/acatec-industrie-4.0-001.webp" class="lightbox">
            <div class="certificate-item clearfix">
              <div class="certi-logo">
                <img src="@/assets/svg/acatech-logo-001.svg" alt="logo" />
              </div>
              <div class="certi-content">
                <div class="certi-title">
                  <h4>Industrie 4.0</h4>
                </div>
                <!-- <div class="certi-id">
                  <span>Membership ID: XXXX</span>
                </div> -->
                <div class="certi-date">
                  <span>03 Juni 2016</span>
                </div>
                <div class="certi-company">
                  <span />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
export default {
  name: 'Resume',
  components: {}
}
</script>
<style lang="scss" scoped>
</style>
