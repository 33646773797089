<template>
  <div class="page-wrapper bg-move-effect">
    <div class="page-content site-content">
      <Header />
      <!-- start page content -->
      <div
        class="content-area single-page-area"
        data-animation="animated-section-rotateRoomBottomIn"
      >
        <div class="single-page-content">
          <div class="content-wrapper">
            <router-view />
          </div>
        </div>
        <Footer />
      </div>
      <!-- end page content -->
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
/* =============================================================================
<router-view />
1. General styles (Buttons, Inputs, Typography)
2. Page loadig animation
3. Page container
4. Site header (Panel with logo & navigation)
5. Site content
6. Pages
7. Sliders (testimonials & portfolio carousels)
8. Portfolio Full info page
9. Single Page Layout (Blog Post page ...)
10. Blog
11. 404 Page
12. Footer
13. Home page
14. Media Queries
15. Elements

============================================================================= */

/* Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

/* Linear Icons Font */
@import url('./assets/fonts/linear-icons-font/style.css');

/* FontAwesome Free Icons Font */
@import url('./assets/fonts/fontawesome-free-5.12.1-web/css/all.min.css');

/* =============================================================================
1. General styles
============================================================================= */

html {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

body {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 15px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 1.65em;
  overflow: hidden;
  color: #333333;
  background-repeat: no-repeat;
  background-position: center;
  background-position: 50% 50%;
  background-size: cover;
}

.add-prespective {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  perspective: 1200px;
}

body.ajax-page-visible {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1500px;
  -moz-perspective: 1500px;
  perspective: 1500px;
}

.transition {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

a {
  color: #4dba87;
  text-decoration: none;
  outline: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: #333333;
  text-decoration: none;
  outline: none;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #333;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 21px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  font-size: 14px;
  color: #555;
  margin: 0 0 10px;
}

p.lead {
  font-size: 18px;
  margin: 25px 0;
}

ol,
ul {
  padding: 0 0 0 40px;
  margin: 1em 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 1em 0;
}

dd {
  margin: 0 0 24px;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

code {
  color: #ee4747;
}

kbd {
  background-color: #222;
  border-radius: 0;
}

.fw-page-builder-content .fw-container {
  padding-left: 0;
  padding-right: 0;
}

.mobile-visible {
  display: none;
  visibility: hidden;
}

.mobile-hidden {
  display: block;
  visibility: visible;
}

/* Form controls */
.contact-form {
  position: relative;
  z-index: 1;
}
.form-group {
  position: relative;
  margin: 0 0 21.5px;
}
.form-control,
.form-control:focus {
  height: 42px;
}

.form-control,
.form-control:focus,
.has-error .form-control,
.has-error .form-control:focus,
input[type='search'],
input[type='password'],
input[type='text'] {
  position: relative;
  border: 2px solid #ced4da;
  border-radius: 4px;
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 10px 25px 10px 12px;
  width: 100%;
  min-width: 100%;
  background: 0 0;
  text-align: left;
  color: inherit;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  font-family: 'Ubuntu', sans-serif;
}

.form-control,
.form-control:focus,
.has-error .form-control,
.has-error .form-control:focus,
input[type='search'],
input[type='password'],
input[type='text'],
.header-search input.form-control {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}

textarea.form-control,
textarea.form-control:focus {
  height: auto;
}

.form-control + .form-control-border {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  border-radius: 1;
  top: 0;
  opacity: 0;
  background: transparent !important;
  border: 1px solid #4dba87;
  border-right-width: 0;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form-control:focus + .form-control-border {
  left: 0;
  right: 0;
  height: 100%;
  z-index: 1;
  opacity: 1;
  border-right-width: 1px;
}

.has-error .form-control + .form-control-border {
  border-color: #ff4c4c;
  background-color: transparent;
}

.form-group label {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: -1;
  color: #a5a6a7;
  transition: 0.3s;
}

.form-group:not(.form-group-checkbox) .form-control:focus ~ label,
.form-group.form-group-focus:not(.form-group-checkbox) .form-control ~ label {
  color: #4dba87;
  top: -20px;
  left: 0;
  z-index: 1;
  font-size: 13px;
}

.form-control,
.form-control:focus,
.has-error .form-control,
.has-error .form-control:focus,
input[type='search'],
input[type='password'],
input[type='text'],
.header-search input.form-control {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}

/* Placeholders */
.form-control::-moz-placeholder {
  color: #adadac;
}

.form-control:-ms-input-placeholder {
  color: #adadac;
}

.form-control::-webkit-input-placeholder {
  color: #adadac;
}
/* /Placeholders */

.form-group .help-block {
  position: absolute;
  display: inline-block;
  padding: 0px 5px;
  right: 0;
  font-size: 0.8em;
  line-height: 1.75em;
  margin: -2px 0 0 10px;
  color: #fff;
  background: #ff4e4e;
  z-index: 1;
}

.form-group .help-block:after {
  content: ' ';
  position: absolute;
  left: 5px;
  bottom: 100%;
  width: 0;
  height: 0;
  border-bottom: 10px solid #ff4e4e;
  border-right: 10px solid transparent;
}
.form-group .help-block:empty {
  display: none;
}

.form-group-with-icon i {
  position: absolute;
  font-size: 16px;
  top: 13px;
  right: 13px;
  color: #d6d7d8;
}

.form-group-with-icon.form-group-focus i {
  color: #4dba87;
}

.form-group.form-group-w-checkbox {
  padding-top: 0;
  padding-bottom: 8px;
  margin-bottom: 17px;
}

.form-group-with-icon.form-group-w-checkbox {
  padding-top: 8px;
  padding-left: 52px;
  margin-bottom: 25px;
}

.form-group .form-control-checkbox + label,
.form-group.form-group-focus .form-control-checkbox + label {
  position: relative;
  display: inline;
  left: 0;
  top: 0;
  font-size: 1em;
  opacity: 1;
}

.form-group .form-control-checkbox,
.form-group.form-group-focus .form-control-checkbox {
  display: inline-block;
  width: auto;
  min-width: auto;
  height: auto;
  top: 2px;
}

.form-group.form-group-w-checkbox .help-block {
  top: 100%;
  left: 0;
  margin-left: 0;
}

.form-group.form-group-with-icon.form-group-w-checkbox .help-block {
  left: 52px;
}

.form-control ~ .form-control-border {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  top: 0;
  opacity: 0;
  background: transparent;
  border: 2px solid #4dba87;
  border-right-width: 0;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form-control:focus ~ .form-control-border {
  left: 0;
  right: 0;
  height: 100%;
  z-index: 1;
  opacity: 1;
  border-right-width: 2px;
}

select,
select:focus,
select:active {
  padding: 5px;
  border: 2px solid #ced4da;
  font-family: inherit;
  border-radius: 0;
  color: inherit;
  outline: 0;
}

.has-error .form-control ~ .form-control-border,
.elementor .has-error .form-control ~ .form-control-border {
  border-color: #ff4c4c;
}
/* /Form controls */

.no-padding {
  padding: 0;
}

.subpage-block {
  margin-bottom: 30px;
}

.text-rotation {
  display: block;
}

.center {
  text-align: center;
}

figure {
  max-width: 100%;
}

.hidden {
  display: none;
  visibility: hidden;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: normal;
  background-color: #f8f8f8;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

/* buttons */
.btn-primary,
.btn-secondary,
button,
input[type='button'],
input[type='submit'],
.wp-block-button .wp-block-button__link,
.elementor .btn-primary,
.elementor .btn-secondary {
  display: inline-block;
  position: relative;
  padding: 0.93em 2em;
  margin-bottom: 0.75em;
  margin-right: 0.25em;
  font-size: 1em;
  line-height: 1.23;
  border: 0;
  outline: 0;
  border: 0;
  color: #fff;
  text-shadow: none;
  background-color: #4dba87;
  border-radius: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 3px 32px 0px rgba(0, 0, 0, 0.15);
}

.btn-primary:last-child,
.btn-secondary:last-child,
button:last-child,
input[type='button']:last-child,
input[type='submit']:last-child,
.wp-block-button .wp-block-button__link:last-child {
  margin-right: 0;
}

.btn-primary:hover,
.btn-primary:focus,
button:hover,
button:focus,
.elementor .btn-primary:hover,
.elementor .btn-primary:focus,
input[type='button']:hover,
input[type='button']:focus,
input[type='submit']:hover,
input[type='submit']:focus {
  background-color: #111;
  color: #fff;
  border: 0;
}

.btn-secondary,
.elementor .btn-secondary {
  background-color: #fff;
  color: #555;
}

.btn-secondary:hover,
.btn-secondary:focus,
.elementor .btn-secondary:hover,
.elementor .btn-secondary:focus {
  color: #fff;
  background-color: #666768;
}

.mfp-container button {
  box-shadow: none;
}

button.mfp-close:hover,
button.mfp-close:visited,
button.mfp-close:focus,
button.mfp-arrow:hover,
button.mfp-arrow:visited,
button.mfp-arrow:focus {
  border: 0;
}
/* / buttons */

.border-top {
  border-top: 2px solid #4dba87;
}

.border-bottom {
  border-bottom: 2px solid #4dba87;
}

/* Footer */
body > footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

/* copyrights block */
body > footer .copyrights {
  display: block;
  margin-bottom: 12px;
  text-align: center;
  font-size: 12px;
  color: #9e9e9e;
}
/* / copyrights block */
/* / Footer */

/* Alignment */

.alignleft {
  float: left;
  margin-right: 20px;
}

.alignright {
  float: right;
  margin-left: 20px;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alignfull {
  margin-left: -100px;
  margin-right: -100px;
  min-width: calc(100% + 200px);
}

.alignwide {
  margin-left: -60px;
  margin-right: -60px;
  min-width: calc(100% + 120px);
}

blockquote.alignleft,
figure.wp-caption.alignleft,
img.alignleft {
  margin: 7px 24px 17px 0;
}

figure.wp-block-image,
.wp-block-image.alignnone {
  margin-top: 24px;
  margin-bottom: 24px;
}

blockquote.alignright,
figure.wp-caption.alignright,
img.alignright {
  margin: 7px 0 17px 24px;
}

.wp-caption.alignright {
  margin: 7px 0 7px 14px;
}

blockquote.aligncenter {
  margin-top: 7px;
  margin-bottom: 7px;
}

img.aligncenter,
.wp-caption.aligncenter {
  margin-top: 24px;
  margin-bottom: 24px;
}

img.alignnone {
  margin-top: 14px;
  margin-bottom: 14px;
}

.site-content blockquote.alignleft,
.site-content blockquote.alignright {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 17px;
  width: 50%;
}

.site-content blockquote.alignleft p,
.site-content blockquote.alignright p {
  margin-bottom: 17px;
}

/* Assistive text */

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: auto;
  line-height: normal;
  padding: 15px 23px 14px;
  position: absolute;
  left: 5px;
  top: 5px;
  text-decoration: none;
  text-transform: none;
  width: auto;
  z-index: 100000; /* Above WP toolbar */
}

/* table */
table,
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin-bottom: 24px;
  width: 100%;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

th {
  border-width: 0 1px 1px 0;
  font-weight: bold;
  padding: 0.5em;
}

td {
  border-width: 0 1px 1px 0;
  padding: 0.5em;
}
/* /table */

.wp-block-separator {
  border: none;
  border-bottom: 2px solid #e5e5e5;
  margin: 1.65em auto;
}

.wp-block-separator.is-style-wide {
  border-bottom-width: 1px;
}

.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
  max-width: 100px;
}

pre.wp-block-verse {
  font-family: inherit;
  background: transparent;
  color: inherit;
  border: 0;
}

.vcentered {
  vertical-align: middle;
  width: 100%;
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.vcentered .row {
  width: 100%;
}

/* White Spaces */
.p-10 {
  padding-top: 10px;
}

.p-20 {
  padding-top: 20px;
}

.p-30 {
  padding-top: 30px;
}

.p-40 {
  padding-top: 40px;
}

.p-50 {
  padding-top: 50px;
}

.p-60 {
  padding-top: 60px;
}

.p-70 {
  padding-top: 70px;
}

.p-80 {
  padding-top: 80px;
}

.p-90 {
  padding-top: 90px;
}

.p-100 {
  padding-top: 100px;
}
/* /White Spaces */

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.flex-v-align {
  vertical-align: middle;
  width: 100%;
  // min-height: 100%;
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
  // height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 0;
}

.flex-v-align .flex-direction-reverse,
.flex-direction-reverse {
  flex-direction: row-reverse !important;
}

/* =============================================================================
2. Page loadig animation
============================================================================= */
.no-js .preloader,
.no-js .preloader-portfolio {
  display: none;
}
.preloader,
.preloader-portfolio {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
}

.preloader-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  background-color: inherit;
  color: inherit;
  opacity: 1;
  transition: opacity 0.3s;
  transform: translate3d(-50%, -50%, 0);
}

.preloader-spinner {
  width: 52px;
  height: 52px;
  margin: 100px auto;
  background-color: #4dba87;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
  }
}

/* =============================================================================
3. Page container
============================================================================= */
/* --- Custom ScrollBar Customization --- */
.ps > .ps__scrollbar-y-rail {
  border-radius: 20px;
}

.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  background-color: #b5b6b7;
}

.ps:hover > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  background-color: #4dba87;
}

.ps > .ps__scrollbar-y-rail {
  width: 10px;
}

.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps:hover.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  width: 6px;
}
/* --- End Custom ScrollBar Customization --- */

.flex-v-align {
  vertical-align: middle;
  width: 100%;
  // min-height: 100%;
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
  // height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 0;
}

.page-wrapper {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0 80px;
  overflow: hidden;
}

.page-content {
  position: relative;
  width: 100%;
  max-width: 1280px;
  min-height: 80vh;
  margin: 10vh auto;
  padding: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1500px;
  -moz-perspective: 1500px;
  perspective: 1500px;
}

.content-area {
  position: absolute;
  left: 90px;
  z-index: 2;
  background-color: transparent;
  height: calc(100% - 20px);
  width: 100%;
  max-width: calc(100% - 180px);
  overflow: auto;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: none;
}

.rsidebar-hidden .content-area {
  max-width: calc(100% - 104px);
}

.animated-sections {
  position: relative;
  height: 100%;
  -webkit-perspective: 1500px;
  -moz-perspective: 1500px;
  perspective: 1500px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.animated-section {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 30px;
  padding: 0;
  opacity: 0;
  overflow: auto;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-repeat: no-repeat;
  background-position: center;
  background-position: 50% 50%;
  background-size: cover;
}

.single-page-content {
  position: relative;
  width: 100%;
  min-height: calc(100% - 56px);
  padding: 0;
  overflow: auto;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: none;
  -webkit-transition: filter 0.2s ease-in-out;
  -moz-transition: filter 0.2s ease-in-out;
  -o-transition: filter 0.2s ease-in-out;
  -ms-transition: filter 0.2s ease-in-out;
  transition: filter 0.2s ease-in-out;
}

.animated-section > .section-content,
.single-page-content > .content-wrapper {
  padding: 50px;
  background-color: #fff;
  position: relative;
  height: auto;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: none;
}

.animated-section > .section-content.without-paddings,
.single-page-content > .content-wrapper.without-paddings {
  padding: 0;
  overflow: hidden;
}

.without-paddings
  .elementor-column-gap-default
  > .elementor-row
  > .elementor-column
  > .elementor-element-populated {
  padding: 0 10px;
}

.section-active,
.no-js .animated-section {
  opacity: 1;
  overflow: auto;
  visibility: visible;
  z-index: 99;
}

/* =============================================================================
5. Site content
============================================================================= */
.page-title {
  display: block;
  position: relative;
  margin: -10px 0 40px;
  text-align: center;
}

.page-title h1 {
  position: relative;
  display: inline-block;
  font-size: 50px;
  font-weight: 700;
  z-index: 1;
  margin: 0;
}

.page-title h1:after {
  position: relative;
  display: block;
  opacity: 1;
  content: '';
  height: 2px;
  width: 50px;
  margin: 10px auto;
  background-color: #e6e7e8;
  z-index: 0;
}

.page-title h1 span,
.page-title h2 span {
  color: #4dba87;
}

.page-title .page-subtitle {
  display: block;
}

.page-title .page-subtitle h4 {
  display: inline-block;
  font-weight: 300;
  padding: 15px 0 0;
  font-size: 15px;
  margin: 0;
  opacity: 0.7;
}

.list-view .page-title {
  margin-bottom: 35px;
}

.elementor {
  margin-left: -10px;
  margin-right: -10px;
}

.page-layout {
  position: relative;
  min-height: 100%;
  height: 100%;
}
/* Page header */
.page-header {
  margin-top: 0;
}
/* /Page Header */

.content-page-with-sidebar .page-content {
  width: 100%;
}

.blog-sidebar {
  position: absolute;
  display: block;
  width: 320px;
  height: calc(80vh - 20px);
  z-index: 0;
  right: 0;
  background-color: #f5f5f5;
  padding: 0;
  float: none;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.22s ease-in-out;
  -moz-transition: all 0.22s ease-in-out;
  -o-transition: all 0.22s ease-in-out;
  -ms-transition: all 0.22s ease-in-out;
  transition: all 0.22s ease-in-out;
  box-shadow: 0px 3px 32px 0px rgba(0, 0, 0, 0.15);
}

.blog-sidebar.open {
  opacity: 1;
  visibility: visible;
  z-index: 99;
  right: 80px;
}

.blog-sidebar .blog-sidebar-content {
  overflow: auto;
  height: 100%;
  padding: 20px;
}

.sidebar-toggle {
  position: absolute;
  display: block;
  width: 48px;
  height: 48px;
  line-height: 46px;
  text-align: center;
  background-color: #fcfcfc;
  left: -48px;
  font-size: 19px;
  top: 25px;
  border: 2px solid #d5d6d7;
  border-right-width: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.sidebar-toggle {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.sidebar-toggle span {
  display: block;
  position: absolute;
  height: 2px;
  width: 40%;
  background: #555;
  border-radius: 5px;
  opacity: 1;
  left: 17px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.sidebar-toggle span:before {
  content: '';
  width: 3px;
  height: 2px;
  background-color: #555;
  position: absolute;
  left: -4px;
  border-radius: 5px;
}

.sidebar-toggle.open span:before {
  left: -2px;
}

.sidebar-toggle span:nth-child(1) {
  top: 14px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.sidebar-toggle span:nth-child(2) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.sidebar-toggle span:nth-child(3) {
  top: 26px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.sidebar-toggle.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 15px;
}

.sidebar-toggle.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.sidebar-toggle.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 26px;
}

.list-view .paging-navigation {
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 0;
  padding-right: 0;
}

/* custom-pages */
.block-title {
  display: block;
  position: relative;
  padding-right: 12px;
  padding-bottom: 5px;
  margin-bottom: 0;
}

.block-title h2,
.block-title h3,
h3.comment-reply-title {
  position: relative;
  display: inline-block;
  font-size: 21px;
  margin: 0 0 20px;
  padding-bottom: 6px;
  z-index: 1;
}

.block-title h2 span,
h3.comment-reply-title span {
  color: #4dba87;
}

.block-title h2:after,
.block-title h3:after,
h3.comment-reply-title:after,
.blog-sidebar .sidebar-title h4:after {
  display: block;
  position: absolute;
  content: '';
  width: 24px;
  background-color: #4dba87;
  height: 2px;
  bottom: 0;
}

.block-title h2:before,
.block-title h3:before,
h3.comment-reply-title:before,
.blog-sidebar .sidebar-title h4:before {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  background-color: #eee;
  height: 2px;
  bottom: 0;
}

.custom-page-content .block {
  margin-bottom: 30px;
}

.custom-page-content .page-content {
  background-color: #fff;
}

.lmpixels-scroll-to-top {
  position: fixed;
  bottom: 10px;
  right: 15px;
  z-index: 100;
  display: inline-block;
  padding: 0;
  background-color: #fafafa;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  cursor: pointer;
  border: 1px solid #e5e5e5;
  color: #333;
  opacity: 1;
  border-radius: 8px;
  visibility: visible;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
}

.lmpixels-scroll-to-top:hover {
  color: #0099e5;
}

.lmpixels-scroll-to-top.hidden-btn {
  opacity: 0;
  visibility: hidden;
}

/* =============================================================================
6. Pages
============================================================================= */
/* =======================================
6.1. Home
======================================= */
/* Social links */
.social-links-block {
  margin: 25px 0 5px;
}

ul.social-links {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

ul.social-links li {
  display: inline-block;
  margin: 0;
  padding: 0;
}

ul.social-links li a {
  display: inline-block;
  margin: 0 1px 5px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  color: #9e9e9e;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  text-align: center;
  vertical-align: middle;
}

ul.social-links li a:hover {
  color: #212121;
}

ul.social-links li a i {
  font-size: 16px;
  line-height: 32px;
}

ul.social-links li a img {
  vertical-align: text-bottom;
  line-height: 100%;
}

ul.social-links li:last-child a {
  margin-right: 0;
}
/* /Social links */

.single-page-content.start-page-template .content-area {
  position: initial;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .start-page .section-inner.vcentered .mask {
    height: 100vh;
  }
}

/* =======================================
6.3. Services
======================================= */
/* Services */
.service-block {
  text-align: center;
  padding: 0;
  margin-bottom: 30px;
}

.service-block img {
  max-width: 100px;
  max-height: 74px;
  margin: 0 0 18px;
}

.service-icon {
  display: block;
  font-size: 48px;
  line-height: 80px;
  color: #fff;
  background-color: #4dba87;
  width: 80px;
  height: 80px;
  border-radius: 100px;
  margin: 5px auto 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.service-icon:hover {
  -webkit-transform: translateY(-7px);
  -ms-transform: translateY(-7px);
  -o-transform: translateY(-7px);
  transform: translateY(-7px);
  -webkit-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 7px 12px rgba(0, 0, 0, 0.15);
}
/* /Services */

/* =======================================
6.4. Portfolio
======================================= */
.portfolio-filters {
  list-style: none;
  padding: 0;
  text-align: center;
  margin: 0 0 30px;
}

.portfolio-filters li {
  display: inline-block;
  margin: 0 15px 0 0;
  font-size: 13px;
}

.portfolio-filters li.active {
  opacity: 1;
}

.portfolio-filters li a,
.portfolio-filters li a:hover,
.portfolio-filters li a:not([href]) {
  color: #333;
  font-size: 13px;
  line-height: 1.5;
  padding: 0;
  text-decoration: none;
  opacity: 0.5;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.portfolio-filters li.active a,
.portfolio-filters li.active a:hover,
.portfolio-filters li a:hover {
  opacity: 1;
}

.pf-load-more {
  margin-top: 40px;
}

.elementor .elementor-widget:not(.elementor-widget-text-editor) .portfolio-grid,
.portfolio-grid {
  margin-left: -8px;
  margin-right: -8px;
  position: relative;
  overflow: hidden;
}

.elementor
  .elementor-widget:not(.elementor-widget-text-editor)
  .portfolio-grid
  .item,
.portfolio-grid .item {
  width: 33.33333%;
  float: left;
  position: relative;
  overflow: hidden;
  margin: 8px;
}

.portfolio-grid img {
  position: relative;
  display: block;
  width: 100%;
}

.elementor
  .elementor-widget:not(.elementor-widget-text-editor)
  .portfolio-grid.one-column
  .item,
.portfolio-grid.one-column .item {
  width: 100%;
}

.elementor
  .elementor-widget:not(.elementor-widget-text-editor)
  .portfolio-grid.two-columns
  .item,
.portfolio-grid.two-columns .item {
  width: calc(50% - 16px);
}

.elementor
  .elementor-widget:not(.elementor-widget-text-editor)
  .portfolio-grid.three-columns
  .item,
.portfolio-grid.three-columns .item {
  width: calc(33.33333% - 16px);
}

.elementor
  .elementor-widget:not(.elementor-widget-text-editor)
  .portfolio-grid.four-columns
  .item,
.portfolio-grid.four-columns .item {
  width: calc(25% - 16px);
}

.elementor
  .elementor-widget:not(.elementor-widget-text-editor)
  .portfolio-grid.five-columns
  .item,
.portfolio-grid.five-columns .item {
  width: calc(20% - 16px);
}

.portfolio-grid img {
  position: relative;
  display: block;
  width: 100%;
}

.portfolio-grid .item a,
.portfolio-rid .item img {
  display: block;
  position: relative;
}

.portfolio-grid .item img {
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.portfolio-grid .item:hover img {
  transform: scale(1.2);
}

.portfolio-item-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.portfolio-grid .item .portfolio-item-img:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border-radius: 10px;
  -webkit-transition: all 0.27s ease-in-out;
  -moz-transition: all 0.27s ease-in-out;
  -o-transition: all 0.27s ease-in-out;
  -ms-transition: all 0.27s ease-in-out;
  transition: all 0.27s ease-in-out;
}

.portfolio-grid .item:hover .portfolio-item-img:after {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.98);
}

.portfolio-grid .project-desc {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.27s ease-in-out;
  -moz-transition: all 0.27s ease-in-out;
  -o-transition: all 0.27s ease-in-out;
  -ms-transition: all 0.27s ease-in-out;
  transition: all 0.27s ease-in-out;
}

.portfolio-grid .project-desc .project-desc-text {
  text-align: center;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-name: fadeOut;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeOut;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.portfolio-grid .item:hover .project-desc .project-desc-text {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

.portfolio-grid.five-columns .item i {
  bottom: 10px;
  right: 10px;
}

.portfolio-grid .item .name {
  display: block;
  padding: 0 0 5px;
  margin: 0;
  font-size: 18px;
  position: relative;
}

.portfolio-grid.five-columns .item .name {
  font-size: 13px;
}

.portfolio-grid .item .category {
  display: inline-block;
  color: #222;
  font-size: 12px;
  line-height: 1.2em;
}

.portfolio-grid.five-columns .item .category {
  bottom: 10px;
  left: 10px;
}

.portfolio-grid .item:hover .project-desc {
  opacity: 1;
}

.load-more-btn-container {
  text-align: center;
}

/* =======================================
6.5. Blog
======================================= */
/* Blog */
.blog-masonry {
  margin: 0 -1em 0.5em;
}

.blog-masonry.one-column .item {
  width: 100%;
  padding: 0 1em 2em;
}

.blog-masonry.one-column .item .blog-card .blog-item-title {
  font-size: 30px;
}

.blog-masonry.one-column .item .blog-card .post-date {
  font-size: 12px;
}

.blog-masonry.one-column .item .blog-card .post-info {
  padding-bottom: 25px;
}

.blog-masonry.two-columns .item {
  width: 50%;
  float: left;
  padding: 0 1em 2em;
}

.blog-masonry.three-columns .item {
  width: 33.333333%;
  float: left;
  padding: 0 1em 2em;
}

.blog-card {
  display: block;
  position: relative;
  padding: 0;
}

.blog-card .post-image {
  width: 100%;
  margin: 0;
}

.blog-card .media-block {
  overflow: hidden;
  border-bottom: 0;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.blog-card .media-block img {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.blog-card .media-block:hover img {
  transform: scale(1.1);
}

.blog-card .post-without-f-image {
  padding-bottom: 46.875%;
  width: 100%;
  background-color: #f5f5f5;
}

.blog-card .post-info {
  padding: 10px 0 15px;
}

.blog-card .blog-item-title {
  margin: 5px 0 0 0;
  font-size: 17px;
}

.blog-card .post-meta {
  padding: 1em;
  margin: 0;
}

.blog-card .category {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 3;
}

.blog-card .category a {
  position: relative;
  background: #fff;
  border-radius: 3px;
  padding: 3px 7px;
  line-height: 1.2em;
  font-size: 11px;
  color: #222;
}

.blog-card .post-date {
  display: inline-block;
  color: #888;
  font-size: 11px;
  line-height: 1.1em;
  font-weight: 400;
  margin-bottom: 7px;
}

.blog-card .media-block {
  position: relative;
}

.sticky-badge {
  position: absolute;
  background-color: #fff;
  right: 10px;
  top: 10px;
  font-size: 12px;
  padding: 0;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  color: #888;
  z-index: 3;
  border-radius: 3px;
}

.blog-card .media-block a .mask {
  position: absolute;
  background: #4f565e;
  background: rgba(255, 255, 255, 0.6);
  display: inline-block;
  font-family: 'Linearicons-Free';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.blog-card .media-block a .mask:after {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -22px 0 0 -22px;
  width: 44px;
  line-height: 44px;
  font-size: 44px;
  text-align: center;
  content: '\e828';
  color: #222;
}

.blog-card .media-block:hover a .mask {
  visibility: visible;
  opacity: 1;
}

.blog-card ul.category li a {
  color: #888;
  font-size: 12px;
  font-weight: 300;
}

.blog-card .post-meta .item,
.post-meta .item a {
  color: #a6a6a6;
}

.post-meta .item {
  display: inline-block;
  font-size: 13px;
  margin-right: 10px;
}

.post-meta .item:before {
  padding-right: 5px;
}

/* =======================================
6.6. Contact
======================================= */

/* Contact form */
.g-recaptcha {
  margin-bottom: 20px;
}
/* / Contact form */

/* Google Map */
.lmpixels-map {
  width: 100%;
  margin: 0 0 40px;
}

.lmpixels-map iframe {
  height: 140px;
  max-width: 100%;
  width: 100%;
  margin: 0;
  line-height: 1;
  border: none;
}
/* / Google Map */

/* Two Columns Form */
.controls.two-columns .left-column {
  width: 47%;
  float: left;
  margin-right: 3%;
}

.controls.two-columns .right-column {
  width: 50%;
  float: right;
}
/* /Two Columns Form */

/* =============================================================================
7. Sliders
============================================================================= */
/* Portfolio Carousel */
.portfolio-page-carousel {
  overflow: hidden;
}

.portfolio-page-carousel .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -22px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.portfolio-page-carousel .owl-nav [class*='owl-'] {
  color: #9e9e9e;
  margin: 0;
  padding: 0;
  width: 44px;
  height: 44px;
  background: #fff;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.portfolio-page-carousel .owl-nav [class*='owl-']:before {
  display: block;
  font-family: 'Linearicons-Free';
  font-size: 30px;
  line-height: 44px;
}

.portfolio-page-carousel .owl-nav .owl-prev {
  position: absolute;
  margin-left: -50px;
  left: 0;
}

.portfolio-page-carousel:hover .owl-nav .owl-prev {
  margin-left: 0;
}

.portfolio-page-carousel .owl-nav .owl-next {
  position: absolute;
  margin-right: -50px;
  right: 0;
}

.portfolio-page-carousel:hover .owl-nav .owl-next {
  margin-right: 0;
}

.portfolio-page-carousel .owl-nav .owl-prev:before {
  content: '\e875';
}

.portfolio-page-carousel .owl-nav .owl-next:before {
  content: '\e876';
}

.portfolio-page-carousel .owl-dots {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 10px 5px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.portfolio-page-carousel .owl-dots .owl-dot {
  display: inline-block;
}

.portfolio-page-carousel .owl-dots .owl-dot.active span,
.portfolio-page-carousel .owl-dots .owl-dot:hover span {
  background: #9e9e9e;
}

.portfolio-page-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px;
  background: #f5f5f5;
  border: 2px solid #9e9e9e;
  display: block;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
/* Portfolio Carousel */

/* =============================================================================
8. Portfolio Full info page
============================================================================= */
.page-ajax-loaded:empty {
  display: none !important;
}

.page-portfolio-loaded {
  position: fixed;
  background-color: #fff;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1003;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: none;
}

.page-portfolio-loaded .preloader {
  background-color: #fff;
}

.page-portfolio-loaded > article {
  height: 100%;
}

.page-portfolio-loaded > article > div {
  height: 100%;
}

.page-portfolio-loaded > article .entry-content {
  padding-bottom: 0;
}

.page-portfolio-loaded .portfolio-page-content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.page-portfolio-loaded .portfolio-page-wrapper {
  max-width: 1170px;
  margin: 0 auto;
  padding: 20px 60px 50px;
}

.portfolio-page-nav {
  text-align: right;
  float: right;
  margin-bottom: 10px;
}

.portfolio-page-nav > div.nav-item {
  position: relative;
  display: inline-block;
  margin: 0 3px;
}

.portfolio-page-nav > div.nav-item a {
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  color: #888;
  background-color: #fafafa;
  border-radius: 5px;
}

.portfolio-page-nav > div.nav-item a:hover {
  background-color: #4dba87;
  color: #fff;
}

.portfolio-page-nav > div.nav-item a i {
  line-height: 40px;
  font-size: 22px;
}

.portfolio-page-title {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: left;
}

.portfolio-page-title h1 {
  display: inline-block;
  margin: 0;
}

.portfolio-page-page-content img {
  max-width: 100%;
}

.portfolio-page-content .embed-video,
.portfolio-page-content .single-image,
.portfolio-page-video,
.portfolio-page-image {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
}

.portfolio-page-wrapper .portfolio-grid {
  margin-bottom: 25px;
}

.project-description {
  display: block;
  position: relative;
  padding: 30px;
  background-color: #fefefe;
  border: 1px solid #f5f5f5;
  margin-left: 20px;
  margin-top: 10px;
}

.portfolio-page-wrapper .block-title h3 {
  position: relative;
  font-size: 17px;
  margin-bottom: 10px;
  z-index: 1;
}

.portfolio-page-wrapper .block-title:before,
.portfolio-page-wrapper .block-title:after {
  display: none;
}

.project-general-info {
  list-style: none;
  padding: 0;
  margin: 0 0 15px;
}

.project-general-info li {
  margin: 0 0 10px;
}

.project-general-info .lnr {
  color: #4dba87;
  margin-right: 6px;
  font-weight: 600;
}

.entry-content .portfolio-page-content .project-general-info p {
  margin: 0;
  font-size: 1em;
}

.project-general-info p {
  margin: 0;
}

.share-buttons {
  margin: 25px 0 0;
}

.share-buttons a {
  display: inline-block;
  margin: 0 3px 0 0;
  padding: 0;
  width: 28px;
  height: 28px;
  color: #888;
  background-color: transparent;
  border: 0;
  text-align: center;
}

.portfolio-page-content .share-buttons a.btn {
  margin: 0 7px 0 0;
  border-radius: 3px !important;
}

.share-buttons a:hover {
  color: #4dba87;
}

.share-buttons a.btn:last-child {
  margin-right: 0;
}

.share-buttons a i {
  font-size: 16px;
  line-height: 26px;
}

/* Tags Block */
.tags-block {
  margin: 25px 0 0;
}

.tags {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tags > li {
  display: inline-block;
}

.tags a {
  display: inline-block;
  font-size: 12px;
  line-height: 1.5em;
  color: #9e9e9e;
  padding: 2px 7px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #d6d7d8;
  margin: 3px 2px 2px;
}

.tags a:first-child {
  margin-left: 0;
}
/* /Tags Block */

.mask-gallery-grid {
  position: absolute;
  background: #4f565e;
  background: rgba(79, 86, 94, 0.6);
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 3px solid transparent;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.mask-gallery-grid:after {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  width: 40px;
  line-height: 40px;
  font-size: 40px;
  text-align: center;
  content: '\f00e';
  color: #fff;
}

.portfolio-grid .item:hover .mask-gallery-grid {
  visibility: visible;
  opacity: 1;
}

.embed-lazy-video {
  background-color: #000;
}

.embed-lazy-video img {
  width: 100%;
  top: -16.84%;
  left: 0;
  opacity: 0.7;
}

.embed-responsive-4by3.embed-youtube-video.embed-lazy-video img,
.embed-vimeo-video.embed-lazy-video img {
  top: 0;
}

.embed-lazy-video .play-button {
  width: 90px;
  height: 60px;
  background-color: #333;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0.8;
}
.embed-lazy-video .play-button:before {
  content: '';
  border-style: solid;
  border-width: 15px 0 15px 26px;
  border-color: transparent transparent transparent #fff;
}
.embed-lazy-video img,
.embed-lazy-video .play-button {
  cursor: pointer;
}
.embed-lazy-video img,
.embed-lazy-video iframe,
.embed-lazy-video .play-button,
.embed-lazy-video .play-button:before {
  position: absolute;
}
.embed-lazy-video .play-button,
.embed-lazy-video .play-button:before {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.embed-lazy-video iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

/* =============================================================================
9. Single Page Layout (Blog Post page ...)
============================================================================= */

.single-page-content .content-area {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: auto;
  min-height: 100%;
}

.list-view
  .single-page-content
  .content-area:not(.projects-grid)
  .page-content {
  padding: 0 0 20px;
}

.single-post .site-content .post-content {
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #fff;
  z-index: 1;
}

.site-content .entry-header,
.list-view .post-content,
.list-view .post-navigation,
.list-view .comments-area,
.list-view .paging-navigation,
.single-post .site-content .post-navigation,
.single-post .site-content .comments-area {
  margin-left: 50px;
  margin-right: 50px;
}

/* table */
.fw-table table {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.875em;
}

.fw-table table tr,
.fw-table table tr.heading-row {
  border-bottom: 1px solid #eee;
}

.fw-table table tr:not(.heading-row):last-child {
  border-bottom: 0;
}

.fw-table table th {
  padding: 7px 12px;
  border-right: 1px solid #eee;
}

.fw-table table td {
  padding: 7px 12px;
  border-right: 1px solid #eee;
}

.fw-table table td:last-child {
  border-right: 0;
}

/* =============================================================================
10. Blog
============================================================================= */
.blog-post-page {
  height: auto;
}

.blog-post-page .page-wrapper {
  position: relative;
  border-left: 15px solid #f5f5f5;
}

.blog-post-content {
  position: relative;
  background-color: #fff;
  padding: 30px;
  margin: -50px 40px 0;
}

blockquote p:last-child {
  margin-bottom: 0;
}

blockquote footer,
blockquote small,
blockquote .small {
  color: #9e9e9e;
  margin-top: 10px;
}

.blog-post-content .block-title {
  margin: 40px 0 10px;
}

.entry-meta {
  color: #9e9e9e;
  margin: 20px 0 30px;
}

.entry-meta a,
.entry-meta .entry-share a {
  color: #9e9e9e;
}

.entry-meta:not(.entry-meta-bottom) a:hover {
  color: #f4511e;
}

.post-tags a:hover {
  color: #222;
}

.entry-meta.entry-meta-bottom .date-author {
  display: inline-block;
  line-height: 28px;
  margin: 5px 0;
  font-size: 1em;
}

.post-tags {
  margin: 25px 0 0;
  text-align: left;
}

.entry-meta.entry-meta-bottom .date-author > span:after {
  content: '';
  height: 14px;
  line-height: 1em;
  display: inline-block;
  margin: 0 10px;
  background-color: #e5e5e5;
  width: 1px;
  top: 2px;
  position: relative;
}

.entry-meta.entry-meta-bottom .date-author > span:last-child:after,
.entry-meta.entry-meta-bottom .date-author > span:first-child:last-child:after {
  display: none;
}

.entry-meta.entry-meta-bottom .date-author i {
  font-weight: 600;
  margin-right: 4px;
}

.site-content .entry-header .entry-meta {
  font-size: 1em;
}

.site-content .entry-meta.entry-meta-bottom {
  display: inline-block;
  width: 100%;
  padding: 2px 0;
  margin: 25px 0 0;
}

.site-content .entry-meta.entry-meta-bottom:empty {
  display: none;
}

.entry-meta.entry-meta-bottom .share-buttons {
  display: inline-block;
  margin: 5px 0;
}

.entry-meta.entry-meta-bottom .share-buttons a:hover {
  color: #4dba87;
}

.entry-meta.entry-meta-bottom .post-info span {
  display: inline-block;
  padding: 5px 0;
  line-height: 1em;
}

.blog-post-content .post-comment-add {
  max-width: 700px;
}

.blog-post-content .post-comments .media {
  margin-top: 30px;
}

.blog-post-content .post-comments > .media {
  margin-top: 0;
}

.post-comments .media-object {
  max-width: 60px;
  border-radius: 50px;
}

.post-comments .media-footer,
.post-comments .media-footer a {
  display: inline-block;
  color: #9e9e9e;
  font-size: 12px;
  line-height: 1em;
}

.post-comments .media-footer a:hover {
  color: #4dba87;
}

.blog-post-content .divider {
  color: #d3d3d3;
  margin: 0 2px;
  min-width: 5px;
}

.post-comments .light-gray {
  color: #9e9e9e;
  font-size: 12px;
}

.site-content.single-post {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.site-content.single-post .post-thumbnail {
  margin-top: 0;
}

.site-content.single-post .post-content,
.site-content.single-post .post-navigation,
.site-content.single-post .comments-area {
  position: relative;
  z-index: 1;
}

.list-view .site-content article {
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
}

@media only screen and (min-width: 1024px) {
  .list-view .site-content article {
    border-bottom: 0;
  }
  .list-view .site-content article:after {
    content: '';
    height: 1px;
    position: absolute;
    display: block;
    bottom: 0;
    width: calc(100% - 100px);
    z-index: 1;
    background-color: #e5e5e5;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .list-view .site-content article:last-child:after {
    display: none;
  }
}

.list-view .site-content article:last-child {
  padding-bottom: 0;
  margin-bottom: 20px;
  border-bottom: 0;
}

.post-content .embed-video {
  margin: 40px 0;
}

.post-content .single-image {
  margin: 40px 0;
}

blockquote {
  position: relative;
  padding: 40px 30px 40px 50px;
  margin: 0;
  font-size: 1.1em;
  font-style: italic;
}

blockquote:before {
  position: absolute;
  left: 0;
  top: 10px;
  color: #e5e5e5;
  z-index: 1;
  content: '\f10d';
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-size: 33px;
  line-height: 33px;
  height: 33px;
  width: 33px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

blockquote:after {
  position: absolute;
  right: 5px;
  bottom: 20px;
  color: #e5e5e5;
  z-index: 1;
  content: '\f10e';
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-size: 22px;
  font-size: 22px;
  line-height: 22px;
  height: 22px;
  width: 22px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

blockquote p {
  font-style: italic;
  font-size: inherit;
  color: #333;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
  padding: 15px 20px;
  margin: 20px 0 20px 20px;
}

.wp-block-quote.is-style-large cite,
.wp-block-quote.is-large cite,
.wp-block-quote.is-large footer,
.wp-block-quote.is-style-large cite,
.wp-block-quote.is-style-large footer {
  font-size: 0.76em;
}

/* ======= Post Thumbnail ======= */
.post-thumbnail {
  // background: #b2b2b2 url(@/assets/img/pattern-light.svg) repeat fixed;
  display: block;
  position: relative;
  width: 100%;
  margin: 40px auto 40px;
  z-index: 0;
}

a.post-thumbnail:hover {
  background-color: #999;
}

.full-width .post-thumbnail img {
  display: block;
  margin: 0 auto;
}

/* ======= Entry Header ====== */
.entry-header {
  position: relative;
  z-index: 1;
}

.entry-title {
  font-size: 40px;
  line-height: 1.3;
  margin: 15px 0 20px 0;
}

.entry-title a {
  color: inherit;
}

.entry-title a:hover {
  color: #4dba87;
}

.site-content .entry-header {
  padding: 5px 0 0;
}

/* ====== Entry Meta ======= */
.entry-meta {
  clear: both;
  line-height: 1.3333333333;
}

.entry-meta + .cat-links:empty {
  display: none;
}

.entry-meta a:hover {
  color: #4dba87;
}

.entry-meta.entry-meta-top > span:after {
  content: '';
  height: 14px;
  line-height: 1em;
  display: inline-block;
  margin: 0 7px;
  background-color: #e5e5e5;
  width: 1px;
  top: 2px;
  position: relative;
}

.entry-meta.entry-meta-top > span:last-child:after,
.entry-meta.entry-meta-top > span:first-child:last-child:after {
  display: none;
}

.entry-meta .featured-post i {
  font-weight: 600;
}

.post-categories {
  display: inline-block;
  margin: 0 0 7px;
  padding: 0;
  list-style: none;
}

.post-categories li {
  display: inline-block;
}

.cat-links {
  display: block;
  padding: 5px 0;
}

.cat-links li a {
  display: inline-block;
  font-size: 14px;
  margin: 0 6px 6px 0;
  color: #fff;
  background-color: #4dba87;
  padding: 2px 5px;
}

.cat-links li:first-child a {
  margin-left: 0;
}

.cat-links li a:hover {
  color: #fff;
  background-color: #4dba87;
}

.byline {
  display: none;
}

.single .byline,
.group-blog .byline {
  display: inline;
}

.site-content .entry-meta {
  margin-bottom: 12px;
}

.site-content footer.entry-meta {
  margin: 25px 0;
  padding: 10px 10px;
  font-size: 15px;
  border: 1px solid #eee;
}

.entry-meta-bottom .tags {
  float: left;
  margin: 5px 0;
}

.entry-meta-bottom .entry-share {
  float: right;
  margin: 0;
}

.entry-meta-bottom .entry-share a:hover {
  color: #fff;
}

.entry-meta-bottom .entry-share h4 {
  display: inline-block;
  margin: 2px 10px 0;
}

/* ======= Entry Content ======= */
.entry-content,
.entry-summary,
.page-content {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: normal;
}

.site-content .entry-summary {
  padding: 6px 0 0;
}

.site-content .entry-summary a.read-more {
  display: block;
  margin-top: 20px;
}

.wrapper .entry-content {
  padding-top: 0;
  padding-bottom: 30px;
}

.entry-content h1:first-child,
.entry-content h2:first-child,
.entry-content h3:first-child,
.entry-content h4:first-child,
.entry-content h5:first-child,
.entry-content h6:first-child,
.entry-summary h1:first-child,
.entry-summary h2:first-child,
.entry-summary h3:first-child,
.entry-summary h4:first-child,
.entry-summary h5:first-child,
.entry-summary h6:first-child,
.page-content h1:first-child,
.page-content h2:first-child,
.page-content h3:first-child,
.page-content h4:first-child,
.page-content h5:first-child,
.page-content h6:first-child {
  margin-top: 0;
}

.entry-content a:hover,
.entry-summary a:hover,
.page-content a:hover,
.comment-content a:hover,
.entry-content a.button,
.entry-summary a.button,
.page-content a.button,
.comment-content a.button {
  text-decoration: none;
}

.entry-content table,
.comment-content table {
  font-size: 14px;
  line-height: 1.2857142857;
}

.entry-content th,
.comment-content th {
  font-weight: 600;
  padding: 10px 12px;
}

.entry-content td,
.comment-content td {
  padding: 10px 12px;
}

.entry-content .edit-link {
  clear: both;
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3333333333;
  margin-top: 20px;
}

.entry-content .edit-link a {
  color: #767676;
  text-decoration: none;
}

.entry-content .edit-link a:hover {
  color: #4dba87;
}

.entry-content .more-link {
  display: block;
  padding-top: 15px;
}

/* ======= Mediaelements ======= */
.hentry .mejs-container {
  margin: 12px 0 18px;
}

.hentry .mejs-mediaelement,
.hentry .mejs-container .mejs-controls {
  background: #000;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-loaded,
.hentry
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-current {
  background: #fff;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-current {
  background: #24890d;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-total,
.hentry
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-total {
  background: rgba(255, 255, 255, 0.33);
}

.hentry .mejs-container .mejs-controls .mejs-time {
  padding-top: 9px;
}

.hentry .mejs-controls .mejs-time-rail span,
.hentry
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-total,
.hentry
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-current {
  border-radius: 0;
}

.hentry .mejs-overlay-loading {
  background: transparent;
}

/* ======= Page links ======= */
.page-links {
  clear: both;
  font-size: 12px;
  font-weight: 300;
  line-height: 2;
  margin: 24px 0;
}

.page-links a,
.page-links > span {
  background: #fff;
  border: 1px solid #fff;
  display: inline-block;
  margin: 0 1px 2px 0;
  text-align: center;
  width: 22px;
  border-radius: 5px;
}

.page-links a:hover {
  background: #4dba87;
  border: 1px solid #4dba87;
  color: #fff;
}

.page-links > .page-links-title {
  height: auto;
  margin: 0;
  padding-right: 7px;
  width: auto;
}

/* ======= Post Formats ======= */
.format-aside .entry-content,
.format-aside .entry-summary,
.format-quote .entry-content,
.format-quote .entry-summary,
.format-link .entry-content,
.format-link .entry-summary {
  padding-top: 0;
}

.site-content .format-link .entry-title,
.site-content .format-aside .entry-title,
.site-content .format-quote .entry-title {
  display: none;
}

/* ======= Post/Image/Paging Navigation ======= */
.nav-links {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: normal;
}

.post-navigation,
.image-navigation {
  margin: 35px auto 10px;
  padding: 0;
}

.image-navigation .previous-image,
.image-navigation .next-image {
  display: inline-block;
  width: auto;
  max-width: 46%;
  padding: 9px 0;
}

.image-navigation .previous-image[rel='next'],
.image-navigation .next-image[rel='next'] {
  float: right;
  text-align: right;
}

.image-navigation a {
  color: #2d2d2d;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.7142857142;
}

.image-navigation a:hover {
  color: #4dba87;
}

.post-navigation .lm-nav-link {
  width: 46%;
}

.post-navigation .meta-nav {
  display: block;
  font-size: 1.1em;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.post-navigation .lm-nav-link a {
  display: block;
  padding: 20px 25px;
}

.post-navigation .lm-nav-link a span {
  display: block;
  padding-bottom: 15px;
  color: #969798;
  font-size: 21px;
}

.post-navigation .nav-link-prev {
  float: left;
  text-align: left;
}

.post-navigation .nav-link-prev a {
  border-left: 1px solid #eee;
}

.post-navigation .nav-link-next {
  float: right;
  text-align: right;
}

.post-navigation .nav-link-next a {
  border-right: 1px solid #eee;
}

.post-navigation .lm-nav-link a:hover span,
.post-navigation .lm-nav-link a:hover h5 {
  color: #4dba87;
}

/* ======= Paging Navigation ======= */
.paging-navigation {
  margin: 50px 0 0;
}

.paging-navigation .loop-pagination {
  display: block;
  margin: 0;
  text-align: center;
}

.paging-navigation .page-numbers {
  display: inline-block;
  font-size: 14px;
  line-height: 1em;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 7px 12px;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid #e5e5e5;
  color: #222;
}

.paging-navigation .page-numbers:last-child {
  margin-right: 0;
}

.paging-navigation a {
  color: #222;
}

.paging-navigation .page-numbers.current,
.paging-navigation .page-numbers.current:hover,
.paging-navigation .page-numbers.current:focus {
  color: #222;
  border: 2px solid #4dba87;
}

.paging-navigation a:hover,
.paging-navigation a:focus {
  background-color: #4dba87;
  color: #fff;
}

/* ======= Attachments ======= */
.attachment .content-sidebar,
.attachment .post-thumbnail {
  display: none;
}

.attachment .entry-content {
  padding-top: 0;
}

.attachment footer.entry-meta {
  text-transform: none;
}

.entry-attachment .attachment {
  margin-bottom: 24px;
}

/* ======= Archives ======= */
.blog .page-title {
  color: #aaa;
  font-size: 24px;
  line-height: 1.5;
  margin: 13px 0 0 0;
}

.taxonomy-description,
.author-description {
  color: #767676;
  font-size: 14px;
  line-height: 1.2857142857;
  padding-top: 18px;
}

.taxonomy-description p,
.author-description p {
  margin-bottom: 18px;
}

.taxonomy-description p:last-child,
.author-description p:last-child {
  margin-bottom: 0;
}

.taxonomy-description a:hover,
.author-description a:hover {
  text-decoration: none;
}

/* ======= Gallery ======= */
.gallery {
  display: inline-block;
  margin-bottom: 20px;
}

.gallery-item {
  float: left;
  margin: 0 4px 4px 0;
  overflow: hidden;
  position: relative;
}

.gallery-columns-1 .gallery-item {
  max-width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 48%;
  max-width: -webkit-calc(50% - 4px);
  max-width: calc(50% - 4px);
}

.gallery-columns-3 .gallery-item {
  max-width: 32%;
  max-width: -webkit-calc(33.3% - 4px);
  max-width: calc(33.3% - 4px);
}

.gallery-columns-4 .gallery-item {
  max-width: 23%;
  max-width: -webkit-calc(25% - 4px);
  max-width: calc(25% - 4px);
}

.gallery-columns-5 .gallery-item {
  max-width: 19%;
  max-width: -webkit-calc(20% - 4px);
  max-width: calc(20% - 4px);
}

.gallery-columns-6 .gallery-item {
  max-width: 15%;
  max-width: -webkit-calc(16.7% - 4px);
  max-width: calc(16.7% - 4px);
}

.gallery-columns-7 .gallery-item {
  max-width: 13%;
  max-width: -webkit-calc(14.28% - 4px);
  max-width: calc(14.28% - 4px);
}

.gallery-columns-8 .gallery-item {
  max-width: 11%;
  max-width: -webkit-calc(12.5% - 4px);
  max-width: calc(12.5% - 4px);
}

.gallery-columns-9 .gallery-item {
  max-width: 9%;
  max-width: -webkit-calc(11.1% - 4px);
  max-width: calc(11.1% - 4px);
}

.gallery-columns-1 .gallery-item:nth-of-type(1n),
.gallery-columns-2 .gallery-item:nth-of-type(2n),
.gallery-columns-3 .gallery-item:nth-of-type(3n),
.gallery-columns-4 .gallery-item:nth-of-type(4n),
.gallery-columns-5 .gallery-item:nth-of-type(5n),
.gallery-columns-6 .gallery-item:nth-of-type(6n),
.gallery-columns-7 .gallery-item:nth-of-type(7n),
.gallery-columns-8 .gallery-item:nth-of-type(8n),
.gallery-columns-9 .gallery-item:nth-of-type(9n) {
  margin-right: 0;
}

.gallery-columns-1 .gallery-item,
.gallery-columns-2 .gallery-item:nth-of-type(2n + 1),
.gallery-columns-3 .gallery-item:nth-of-type(3n + 1),
.gallery-columns-4 .gallery-item:nth-of-type(4n + 1),
.gallery-columns-5 .gallery-item:nth-of-type(5n + 1),
.gallery-columns-6 .gallery-item:nth-of-type(6n + 1),
.gallery-columns-7 .gallery-item:nth-of-type(7n + 1),
.gallery-columns-8 .gallery-item:nth-of-type(8n + 1),
.gallery-columns-9 .gallery-item:nth-of-type(9n + 1) {
  clear: left;
}

.gallery-columns-1.gallery-size-medium figure.gallery-item:nth-of-type(1n + 1),
.gallery-columns-1.gallery-size-thumbnail
  figure.gallery-item:nth-of-type(1n + 1),
.gallery-columns-2.gallery-size-thumbnail
  figure.gallery-item:nth-of-type(2n + 1),
.gallery-columns-3.gallery-size-thumbnail
  figure.gallery-item:nth-of-type(3n + 1) {
  clear: left;
}

.gallery-caption {
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
  max-height: 50%;
  opacity: 0;
  padding: 6px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  width: 100%;
}

.gallery-caption:before {
  content: '';
  height: 100%;
  min-height: 49px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.gallery-item:hover .gallery-caption {
  opacity: 1;
}

.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

/* ======= Contributor Page ======= */
.contributor {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 48px 10px;
}

.contributor:first-of-type {
  padding-top: 24px;
}

.contributor-info {
  margin: 0 auto;
}

.contributor-avatar {
  border: 1px solid rgba(0, 0, 0, 0.1);
  float: left;
  margin: 0 30px 20px 0;
  padding: 2px;
}

.contributor-name {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
}

.contributor-bio a:hover {
  text-decoration: none;
}

.contributor-posts-link {
  display: inline-block;
  line-height: normal;
  padding: 10px 30px;
}

.contributor-posts-link:before {
  content: '\f443';
}

/* ======= Blog Sidebar ======= */
.blog-sidebar .sidebar-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 0.9em;
  background-color: #fff;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.blog-sidebar li {
  font-size: 1em;
}

.sidebar-item .menu-item i {
  display: none;
}

.sidebar .sidebar-item ul {
  margin: 0;
  list-style: none;
}

.sidebar .sidebar-item ul li {
  list-style: none;
}

.blog-sidebar select {
  width: auto;
  max-width: 100%;
}

.sidebar-item > select,
.sidebar-item > form > select {
  margin: 14px 20px 19px;
}

.blog-sidebar p {
  font-size: 1em;
}

.blog-sidebar ul,
.blog-sidebar .textwidget,
.blog-sidebar .tagcloud {
  display: inline-block;
  max-width: 100%;
  padding: 2px 20px 15px;
  margin: 4px 0;
  list-style: none;
}

.blog-sidebar ul ul {
  padding: 5px;
}

.blog-sidebar ul li {
  padding-left: 15px;
  margin: 8px 0;
  position: relative;
  color: #aaa;
}

.blog-sidebar div:not(.sidebar-item) > ul > li,
.blog-sidebar .sidebar-item > ul > li {
  padding-left: 0;
}

.blog-sidebar .sidebar-item ul > li:first-child,
.blog-sidebar .sidebar-item > ul > li:first-child {
  margin-top: 5px;
}

.blog-sidebar .sidebar-item > ul > li:last-child {
  margin-bottom: 0;
}

.blog-sidebar ul li ul {
  padding: 0;
  margin: 8px 0 0 0;
}

.blog-sidebar ul li ul li:first-child {
  margin-top: 5px;
}

.blog-sidebar ul li ul li:last-child {
  margin-bottom: 0;
}

.blog-sidebar .sub-menu,
.blog-sidebar .children {
  width: 100%;
}

.blog-sidebar .sub-menu ul {
  margin-top: 0;
  margin-bottom: 0;
}

.blog-sidebar .sub-menu li:before {
  display: none;
}

.blog-sidebar a {
  color: #555;
}

.blog-sidebar a:hover {
  color: inherit;
}

.blog-sidebar .searchform {
  margin: 4px 0;
}

.blog-sidebar .sidebar-item:first-child .searchform {
  margin-top: 0;
}

.blog-sidebar h6,
.blog-sidebar .sidebar-title {
  display: block;
  position: relative;
  padding: 5px 22px 0;
  margin: 15px 0 0 0;
  color: #fff;
}

.blog-sidebar .sidebar-title h4 {
  display: inline-block;
  font-size: 17px;
  position: relative;
  margin: 0 0 2px;
  padding-bottom: 6px;
}

.blog-sidebar .sidebar-title h4:after {
  width: 18px;
  height: 2px;
  bottom: 0;
}

.blog-sidebar .sidebar-title h4:before {
  height: 2px;
  bottom: 0;
}

.blog-sidebar .post-date {
  font-size: 11px;
}

.blog-sidebar .separator {
  display: none;
}

.blog-sidebar h6 .demo-icons,
.blog-sidebar .sidebar-title .demo-icons,
.blog-sidebar .sidebar-title .fa {
  color: #fff;
  margin-right: 0.6em;
}

.blog-sidebar .sidebar-title .rsswidget img {
  vertical-align: baseline;
}

/* Calendar */
.calendar_wrap {
  padding: 12px 22px;
}

.calendar_wrap table {
  width: 100%;
  border: 0;
}

.calendar_wrap th {
  background-color: #e5e5e5;
  text-align: center;
  font-size: 11px;
  border: 0;
}

.calendar_wrap td {
  background-color: #fcfcfc;
  text-align: center;
  border: 0;
}

.calendar_wrap td#today {
  background-color: #4dba87;
  color: #fff;
  padding: 0 3px;
}

/* hiding the Duplicated widget title */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
}

/* ======= Search form =======  */
.search-form {
  position: relative;
  overflow: hidden;
}

.search-form label {
  font-weight: inherit;
  width: 100%;
  margin: 0;
}

.search-form label span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  text-indent: -3000px;
  opacity: 0.35;
  margin: 0;
}

.search-form label:after {
  height: 48px;
  width: 48px;
  position: absolute;
  top: 0;
  right: 0;
  content: '\f002';
  font-size: 1.2em;
  font-family: 'FontAwesome';
  line-height: 48px;
  text-align: center;
  border-left: none;
  color: #d5d6d7;
}
.search-form input[type='search'],
.search-form input[type='text'] {
  padding: 10px 50px 10px 16px;
  margin-bottom: 0;
  position: relative;
  border: none;
  width: 100%;
  height: 48px;
  color: inherit;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.blog-sidebar .search-form input[type='search'],
.blog-sidebar .search-form input[type='text'] {
  border: none;
  background-color: transparent;
}

.search-form input[type='submit'],
.search-form input[type='submit']:hover,
.search-form input[type='submit']:focus {
  position: absolute;
  top: 0;
  right: 0;
  height: 48px;
  width: 48px;
  background: transparent;
  border: none;
  z-index: 1000;
  cursor: pointer;
  text-indent: -200px;
  overflow: hidden;
  border-radius: 0;
  box-shadow: none;
}

.search-form input[type='text']:focus + input[type='submit'] {
  margin-right: 0;
}

/* ======= Search Results: nothing found ======= */
.page-content-none {
  padding-top: 30px;
  text-align: center;
}

.page-content-none .search-form {
  max-width: 270px;
  margin: 30px auto;
}

.page-content-none .search-form input[type='search'] {
  border: 1px solid #e5e5e5;
}

.page-content-none .search-form input[type='search']:focus {
  border-color: #aaa;
}

/* =============================================================================
11. 404 Page
============================================================================= */
.single-page-content > .content-wrapper.not-found {
  min-height: calc(80vh - 76px);
}

.nothing-found {
  text-align: center;
}

.nothing-found h1 {
  font-size: 160px;
  line-height: 1.2em;
  margin: 0;
  color: #e5e5e5;
  text-align: center;
}

.nothing-found p {
  display: inline-block;
  color: #fff;
  background-color: #4dba87;
  padding: 0 10px;
  font-size: 16px;
}

/* =============================================================================
12. Footer
============================================================================= */
.site-footer {
  display: block;
  position: relative;
  padding: 15px 50px;
  background-color: #f8f8f8;
}

.footer-social {
  float: left;
}

.site-footer .footer-social-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.site-footer .footer-social-links li {
  display: inline-block;
  margin-right: 25px;
}
.site-footer .footer-social-links li:last-child {
  margin-right: 0;
}

.site-footer .footer-social-links li a {
  color: #333;
  text-decoration: none;
  line-height: 21px;
  font-size: 13px;
  opacity: 0.7;
}

.site-footer .footer-social-links li a:hover {
  opacity: 1;
}

.home-page-second-style .home-content {
  position: relative;
  width: 100%;
  margin: 7% 0;
}

.home-page-second-style .home-text {
  text-align: left;
  padding-right: 0;
  padding-left: 10px;
}

.home-page-second-style .home-text.hp-left {
  padding-left: 0;
  padding-right: 25px;
}

.home-page-second-style .home-text h1 {
  font-size: 48px;
  line-height: 56px;
  margin-top: 0;
  margin-bottom: 20px;
}

.home-page-second-style .home-text h4 {
  font-size: 16px;
  color: #aaa;
  font-weight: 300;
  margin-bottom: 10px;
}

.home-text-subtitle {
  font-size: 17px;
  color: #aaa;
  font-weight: 300;
  text-transform: uppercase;
  margin-top: -12px;
  padding-bottom: 20px;
}

.home-page-second-style .home-buttons {
  margin-top: 27px;
}

.home-page-second-style .hp-dots {
  position: absolute;
  opacity: 0.04;
  top: -5%;
  left: -3%;
  content: '';
  height: 70%;
  width: 38%;
  background-image: -webkit-repeating-radial-gradient(
    center center,
    #000,
    #000 2px,
    transparent 0px,
    transparent 100%
  );
  background-image: -moz-repeating-radial-gradient(
    center center,
    #000,
    #000 2px,
    transparent 0px,
    transparent 100%
  );
  background-image: -ms-repeating-radial-gradient(
    center center,
    #000,
    #000 2px,
    transparent 0px,
    transparent 100%
  );
  -webkit-background-size: 30px 30px;
  -moz-background-size: 30px 30px;
  background-size: 30px 30px;
  z-index: -1;
}

.home-page-second-style .flex-direction-reverse .hp-dots {
  right: -3%;
  left: auto;
}

@media only screen and (max-width: 1100px) {
  .home-page-second-style .home-content {
    margin: 5% 0;
  }
}

@media only screen and (max-width: 767px) {
  .home-page-second-style .home-photo {
    width: 60%;
    padding-bottom: calc(60% - 36px);
    margin: 30px auto;
  }

  .home-page-second-style .home-text {
    padding-right: 0;
    padding-left: 0;
  }

  .home-page-second-style .home-text.hp-left {
    padding-right: 0;
    padding-left: 0;
  }

  .home-page-second-style .home-text h1,
  .home-page-second-style .home-text h4 {
    text-align: center;
  }

  .home-page-second-style .home-buttons {
    text-align: center;
  }
}

@media only screen and (max-width: 648px) {
  .home-page-second-style .home-photo,
  .home-page-second-style .home-photo.hp-left {
    width: 70%;
    padding-bottom: calc(70% - 36px);
  }
}

@media only screen and (max-width: 500px) {
  .home-page-second-style .home-photo,
  .home-page-second-style .home-photo.hp-left {
    width: 90%;
    padding-bottom: calc(90% - 36px);
  }

  .home-page-second-style .home-text h1 {
    font-size: 36px;
    line-height: 42px;
  }

  .home-page-second-style.home-text h4 {
    font-size: 14px;
  }
}

/* Home Page Third Style */
.home-page-third-style.vcentered {
  min-height: calc(80vh - 20px);
  width: calc(100% + 100px);
  margin-top: -50px;
  margin-left: -50px;
}

.home-page-third-style .home-content {
  position: relative;
  width: 100%;
  margin: 0;
}

.home-page-third-style .home-photo {
  display: block;
  position: relative;
  width: 100%;
  min-height: calc(80vh - 20px);
  background-color: #fff;
  overflow: hidden;
}

.home-page-third-style .home-photo .hp-inner {
  background-image: url(./assets/img/logo/oscharko-001.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  display: block;
  position: absolute;
  top: -5px;
  bottom: -5px;
  width: auto;
  left: -5px;
  right: -5px;
}

.home-page-third-style .without-padding {
  padding-left: 0;
  padding-right: 25px;
}

.home-page-third-style .flex-direction-reverse .without-padding {
  padding-left: 0;
  padding-right: 0;
}

.home-page-third-style .flex-direction-reverse .home-text {
  padding-left: 40px;
  padding-right: 20px;
}

.home-page-third-style .home-text {
  text-align: left;
  padding: 0 15px;
}

.home-page-third-style .home-text h1 {
  font-size: 42px;
  font-weight: 700;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 20px;
}

.home-page-third-style .home-text h1 span {
  font-weight: 300;
}

.home-page-third-style .home-text h4 {
  font-size: 16px;
  color: #aaa;
  font-weight: 300;
  margin-bottom: 10px;
}

.home-page-third-style .home-buttons {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .home-page-third-style .without-padding,
  .home-page-third-style .flex-direction-reverse .without-padding {
    padding-right: 0;
  }

  .home-page-third-style .home-text h1 {
    font-size: 44px;
  }

  .home-page-third-style .home-photo {
    top: 10px;
    min-height: calc(50vh - 20px);
  }

  .home-page-third-style .home-text {
    padding-top: 50px;
  }

  .home-page-third-style.vcentered {
    width: calc(100% + 90px);
    min-height: auto;
  }
}

.home-bgvideo {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: calc(80vh - 20px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.home-bgvideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .home-bgvideo {
    padding-bottom: calc(50vh - 20px);
  }
}

/* =============================================================================
14. Media Queries
============================================================================= */
@media only screen and (max-width: 1400px) {
  .page-wrapper {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .page-wrapper {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 1025px) {
  .menu-toggle {
    display: none;
  }

  body::-webkit-scrollbar,
  .pt-page::-webkit-scrollbar,
  .content-area::-webkit-scrollbar,
  .header::-webkit-scrollbar,
  .portfolio-page-content::-webkit-scrollbar,
  .blog-sidebar .blog-sidebar-content::-webkit-scrollbar,
  .site-menu::-webkit-scrollbar {
    position: absolute;
    width: 5px;
    margin-left: -5px;
  }

  body::-webkit-scrollbar-track,
  .pt-page::-webkit-scrollbar-track,
  .content-area::-webkit-scrollbar-track,
  .header::-webkit-scrollbar-track,
  .portfolio-page-content::-webkit-scrollbar-track,
  .blog-sidebar .blog-sidebar-content::-webkit-scrollbar-track,
  .site-menu::-webkit-scrollbar-track {
    background-color: #fcfcfc;
  }

  body::-webkit-scrollbar-thumb,
  .pt-page::-webkit-scrollbar-thumb,
  .content-area::-webkit-scrollbar-thumb,
  .header::-webkit-scrollbar-thumb,
  .portfolio-page-content::-webkit-scrollbar-thumb,
  .blog-sidebar .blog-sidebar-content::-webkit-scrollbar-thumb,
  .site-menu::-webkit-scrollbar-thumb {
    background-color: #4cbb87;
  }
}

.svg-site-header {
  width: 70px;
  opacity: 0.6;
}
.svg-site-header:hover {
  opacity: 1;
}
.dl-menuwrapper ul {
  margin-top: -5px;
}

@media only screen and (min-width: 1025px) {
  .header {
    position: absolute;
  }

  .header-content {
    position: relative;
    display: inline-block;
    background-color: #4dba87;
    width: 70px;
    height: 100%;
    max-height: calc(80vh - 20px);
    min-height: calc(80vh - 20px);
    text-align: center;
    padding: 0 0 0 0;
    margin-right: 10px;
    overflow: auto;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    z-index: 100;
  }

  .r-sidebar {
    position: relative;
    display: inline-block;
    background-color: #f5f5f5;
    width: 100%;
    max-width: 70px;
    height: 100%;
    max-height: calc(80vh - 20px);
    min-height: calc(80vh - 20px);
    text-align: center;
    padding: 0;
    margin-left: 10px;
    overflow: auto;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    z-index: 100;
  }

  .menu-toggle {
    display: none;
  }
}

.header-photo {
  position: relative;
  margin: 10px auto 0;
  z-index: 1;
}

.header-photo img {
  height: auto;
  width: auto;
  max-width: 60px;
}

.header-titles h2 {
  display: block;
  font-size: 27px;
  font-weight: 600;
  color: #fff;
  margin: 0 0 7px;
  padding-top: 15px;
  line-height: 1.2em;
}

.header-titles h4 {
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  margin: 5px 0;
  line-height: 1.2em;
}

.social-links ul li {
  margin-top: -5px !important;
  padding: 0;
  display: inline-block;
}

.header-buttons {
  margin-top: 50px;
}

.header-buttons .btn-primary {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}

.header-buttons .btn-primary:hover {
  background-color: #fff;
  color: #4dba87;
  border-color: #fff;
}

.header .copyrights {
  color: #fff;
  width: 380px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 10px 15px;
  line-height: 14px;
  font-size: 12px;
  text-align: center;
}

/* Menu Button */
.menu-button {
  position: absolute;
  display: block;
  width: 100%;
  height: 50px;
  top: calc(50% - 22px);
  line-height: 46px;
  text-align: center;
}

.menu-button {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.menu-button span {
  display: block;
  position: absolute;
  height: 3px;
  width: 40%;
  background: #fff;
  border-radius: 5px;
  opacity: 1;
  left: 19px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.menu-button span:nth-child(1) {
  top: 14px;
  left: 25px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.menu-button span:nth-child(2) {
  top: 23px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.menu-button span:nth-child(3) {
  left: 22px;
  top: 32px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.menu-button:not(.open):hover span {
  left: 21px;
}

.menu-button.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 11px;
}

.menu-button.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.menu-button.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 31px;
  left: 25px;
}

/* Main Menu */
.site-menu {
  position: absolute;
  background-color: #fcfcfc;
  height: calc(80vh - 20px);
  width: 100vw;
  max-width: 380px;
  z-index: 0;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  -webkit-transition: all 0.22s ease-in-out;
  -moz-transition: all 0.22s ease-in-out;
  -o-transition: all 0.22s ease-in-out;
  -ms-transition: all 0.22s ease-in-out;
  transition: all 0.22s ease-in-out;
  box-shadow: 0px 3px 32px 0px rgba(0, 0, 0, 0.15);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.site-menu.open {
  visibility: visible;
  opacity: 1;
  // top: 0px;
  left: 70px;
  z-index: 99;
}

.site-menu .main-menu {
  list-style: none;
  width: 100%;
  text-align: right;
  padding: 40px 45px;
  margin: 0;
}

.site-menu .main-menu li {
  position: relative;
  padding-right: 0;
  margin: 15px 0;
}

.site-menu .main-menu li a {
  position: relative;
  color: #333;
  font-size: 18px;
}

.site-menu .main-menu li a:before {
  content: '';
  display: block;
  position: absolute;
  width: 40px;
  height: 2px;
  top: calc(50% - 1px);
  left: -80px;
  background-color: #e6e7e8;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.22s ease-in-out;
  -moz-transition: all 0.22s ease-in-out;
  -o-transition: all 0.22s ease-in-out;
  -ms-transition: all 0.22s ease-in-out;
  transition: all 0.22s ease-in-out;
}

.site-menu .main-menu li.dl-back a:before {
  display: none;
}

.site-menu .main-menu li.current-menu-item > a:before,
.site-menu .main-menu > li.current-menu-parent > a:before,
.site-menu .main-menu li:hover > a:before {
  opacity: 1;
  visibility: visible;
  left: -52px;
}

.dl-menuwrapper li:not(.dl-back) > a:not(:only-child):after {
  position: absolute;
  float: right;
  right: -20px;
  top: 50%;
  font-family: 'Linearicons-Free';
  // speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: -12px;
  content: '\e876';
  z-index: 0;
  opacity: 0.7;
  padding-left: 10px;
}

.dl-back > a {
  background-color: transparent;
}

/* Right Sidebar */
.sidebar-button {
  position: absolute;
  top: calc(50% - 25px);
  width: 100%;
  height: 50px;
  cursor: pointer;
}

.sidebar-button span {
  display: inline-block;
  background-color: #868788;
  border-radius: 10px;
  width: 6px;
  height: 6px;
  margin: 22px 0;
}

.sidebar-button span,
.sidebar-button span:hover,
.sidebar-button span:focus {
  outline: 0;
}

.sidebar-button.open span {
  display: block;
  position: absolute;
  height: 3px;
  width: 40%;
  border-radius: 5px;
  opacity: 1;
  left: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.sidebar-button.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0;
}

.sidebar-button.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.sidebar-button.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 0;
  left: 20px;
}

.site-menu.profile-card-on {
  max-width: 650px;
}

.site-menu.profile-card-on .main-menu {
  width: 50%;
  margin-left: auto;
}

.profile-card {
  background-color: #fcfcfc;
  width: 50%;
  padding: 40px 45px;
  z-index: 1;
}

.profile-card .profile-img {
  display: block;
}

.profile-card-block .profile-img,
.profile-card-block .profile-img img {
  height: auto;
  max-height: 170px;
  width: auto;
  max-width: 170px;
  margin: -8px auto;
  border-radius: 50%;
}

.profile-card h3.site-title {
  display: block;
  margin: 25px 0 -5px 0 !important;
  font-size: 22px;
  font-weight: 300;
}

.profile-card h5.site-subtitle {
  display: block;
  font-weight: 400;
  color: #9e9e9e;
  margin: 5px 0 0;
  font-size: 14px;
}

.sidebar-open .single-page-content {
  filter: blur(9px) opacity(0.5);
  -webkit-filter: blur(9px) opacity(0.5);
  -moz-filter: blur(9px) opacity(0.5);
  -o-filter: blur(9px) opacity(0.5);
  -ms-filter: blur(9px) opacity(0.5);
}

@media only screen and (max-width: 1024px) {
  .header {
    position: unset;
    width: 100%;
    max-width: 100%;
    height: 50px;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #4dba87;
    z-index: 101;
    text-align: center;
    overflow: visible;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .header .copyrights {
    right: 0;
    width: 100%;
    position: relative;
    margin-top: 30px;
  }

  .menu-button {
    position: relative;
    float: right;
    width: 60px;
    top: calc(50% - 25px);
    right: 10px;
  }

  .menu-button.open span:nth-child(1) {
    top: 14px;
  }

  .menu-button.open span:nth-child(3) {
    top: 31px;
  }

  .site-menu {
    top: 50px;
    bottom: 0;
    left: auto;
    right: -100%;
    height: auto;
    min-height: auto;
    border: 0;
  }

  .site-menu.open {
    left: auto;
    right: 0;
    z-index: 100;
  }

  .header-photo {
    float: left;
    max-width: 130px;
    margin: 0 0 0 15px;
  }

  .header-photo img {
    max-width: none;
    max-height: 36px;
    margin-top: 7px;
  }

  .header-titles {
    position: relative;
    float: left;
  }

  .header-titles h2 {
    line-height: 50px;
    font-size: 24px;
    padding: 0;
    margin: 0 0 0 20px;
  }

  .r-sidebar {
    position: absolute;
    display: inline-block;
    width: 100%;
    max-width: 60px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.05);
    bottom: 0;
    right: 0;
    text-align: center;
    padding: 0;
    margin: 0;
    overflow: auto;
    border-radius: 0;
    z-index: 100;
  }

  .sidebar-button {
    position: relative;
    float: right;
    width: 60px;
    height: 100%;
    overflow: hidden;
  }

  .sidebar-button.open span,
  .sidebar-button.open span:nth-child(3) {
    left: 18px;
  }

  .page-wrapper {
    padding: 0;
  }

  .page-content {
    padding: 0;
    margin: 0;
    border-radius: 0;
    min-height: 100%;
  }

  .content-area {
    max-width: 100%;
    left: 0;
    top: 50px;
    height: calc(100% - 50px);
  }

  .rsidebar-hidden .content-area {
    max-width: 100%;
    height: calc(100% - 50px);
  }

  .animated-section,
  .single-page-content {
    border-radius: 0;
    overflow: auto !important;
  }

  .mobile-menu-hide {
    width: 0;
    right: 0;
    margin-right: -100%;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .mobile-site-title a,
  .mobile-site-title a:hover,
  .mobile-site-title a:focus {
    position: absolute;
    display: inline-block;
    font-weight: 600;
    padding: 10px;
    z-index: 99;
    left: 20px;
    top: 10px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    color: #222;
  }

  .site-content .entry-header,
  .single-page-content .post-content {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
  }

  .mobile-visible {
    display: block;
    visibility: visible;
  }

  .mobile-hidden {
    display: none !important;
    visibility: hidden !important;
  }

  .border-block-top-110 {
    display: none;
  }

  .page-content,
  .single-page-content .content-area .page-content,
  .single-post .single-page-content .content-area,
  .list-view .single-page-content .content-area:not(.projects-grid) {
    padding: 0;
  }

  .single-fw-portfolio .single-page-content .content-area {
    padding: 0;
  }

  .ajax-page-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .blog-masonry.three-columns .item {
    width: 50%;
  }

  .single-page-content .content-area {
    display: block;
  }

  .site-content.single-post .post-thumbnail {
    margin-top: 0;
  }

  .content-page-with-sidebar .page-content {
    width: 100%;
    display: block;
  }

  .blog-sidebar {
    top: 50px;
    bottom: 0;
    height: auto;
    width: 330px;
    right: -330px;
    border: 0;
    box-shadow: 0px 3px 32px 0px rgba(0, 0, 0, 0.15);
  }

  .blog-sidebar.open {
    right: 0;
  }

  .list-view .post-thumbnail {
    margin-top: 0;
  }

  .post-thumbnail {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .alignwide {
    margin-left: -50px;
    margin-right: -50px;
    min-width: calc(100% + 48px);
  }

  .page-title h1 {
    margin: 0;
  }

  .page-title .page-subtitle {
    position: relative;
    right: auto;
  }

  .page-title .page-subtitle h4 {
    margin: 5px 0;
  }

  .single-post .site-content .post-content {
    padding-left: 30px;
    padding-right: 30px;
  }

  .single-post .site-content .post-navigation,
  .single-post .site-content .comments-area {
    margin-left: 30px;
    margin-right: 30px;
  }

  .sidebar-toggle {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    top: 105px;
    text-align: center;
    background-color: #fcfcfc;
    left: -40px;
    font-size: 19px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .sidebar-toggle span {
    left: 14px;
  }

  .sidebar-toggle span:nth-child(1) {
    top: 10px;
  }

  .sidebar-toggle span:nth-child(2) {
    top: 16px;
  }

  .sidebar-toggle span:nth-child(3) {
    top: 22px;
  }

  .sidebar-toggle.open span:nth-child(1) {
    top: 12px;
  }

  .sidebar-toggle.open span:nth-child(3) {
    top: 21px;
  }

  .project-description {
    margin-left: 0;
  }

  .post-content .single-image {
    margin: 30px 0;
  }

  .site-footer {
    border-bottom: 2px solid #e6e7e8;
  }

  .blog-sidebar ul li {
    margin: 12px 0;
  }
  .blog-sidebar ul li ul {
    margin: 12px 0 0 0;
  }

  .blog-sidebar .sidebar-title h4 {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 768px) {
  .fw-container {
    width: auto;
  }
}

@media only screen and (max-width: 768px) {
  .elementor
    .elementor-widget:not(.elementor-widget-text-editor)
    .portfolio-grid
    .item,
  .portfolio-grid .item {
    width: calc(50% - 16px);
  }

  .info-list {
    margin-top: 20px;
  }

  .blog-post-content {
    padding: 0;
    margin: 30px 0 0;
  }

  .blog-masonry.two-columns .item,
  .blog-masonry.three-columns .item {
    width: 100%;
  }

  .elementor
    .elementor-widget:not(.elementor-widget-text-editor)
    .portfolio-grid
    .item,
  .elementor
    .elementor-widget:not(.elementor-widget-text-editor)
    .portfolio-grid.three-columns
    .item,
  .portfolio-grid .item,
  .portfolio-grid.three-columns .item {
    width: calc(50% - 16px);
  }

  .elementor
    .elementor-widget:not(.elementor-widget-text-editor)
    .portfolio-grid.four-columns
    .item,
  .elementor
    .elementor-widget:not(.elementor-widget-text-editor)
    .portfolio-grid.five-columns
    .item,
  .portfolio-grid.four-columns .item,
  .portfolio-grid.five-columns .item {
    width: calc(33.3333333% - 16px);
  }

  .page-portfolio-loaded .portfolio-page-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .portfolio-page-carousel .owl-nav .owl-prev {
    margin-left: 0;
  }

  .portfolio-page-carousel .owl-nav .owl-next {
    margin-right: 0;
  }

  .portfolio-page-carousel .owl-nav .owl-prev,
  .portfolio-page-carousel .owl-nav .owl-next {
    background-color: rgba(255, 255, 255, 0.55);
  }

  .nothing-found h1 {
    font-size: 88px;
  }

  .entry-meta.entry-meta-bottom .share-buttons {
    display: block;
    float: none;
  }

  .entry-meta-bottom .tags {
    display: block;
    float: none;
  }

  .entry-meta.entry-meta-bottom .date-author + .share-buttons {
    margin-top: 10px;
  }

  .site-content .entry-header,
  .list-view .post-content,
  .list-view .post-navigation,
  .list-view .comments-area,
  .single-post .site-content .post-content,
  .single-post .site-content .post-navigation,
  .single-post .site-content .comments-area {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .single-post .site-content .post-navigation {
    margin: 35px 0;
  }

  .animated-section > .section-content,
  .single-page-content > .content-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }

  .alignfull {
    margin-left: -40px;
    margin-right: -40px;
    min-width: calc(100% + 80px);
  }

  .alignwide {
    margin-left: -20px;
    margin-right: -20px;
    min-width: calc(100% + 38px);
  }

  .list-view .paging-navigation {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .single-post .site-content .post-content {
    position: relative;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    z-index: 1;
  }

  .fw-table {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .comment-metadata {
    padding: 0;
    border: 0;
    margin: 0 0 5px;
    display: block;
  }

  .alignfull {
    margin-left: -40px;
    margin-right: -40px;
    min-width: calc(100% + 80px);
  }

  .single-page-content > .content-wrapper.not-found {
    min-height: calc(80vh - 76px);
  }

  .single-page-content {
    min-height: calc(100% - 118px);
  }
}

@media only screen and (max-width: 650px) {
  .site-menu.profile-card-on {
    display: block;
    max-width: 380px;
  }

  .profile-card,
  .site-menu.profile-card-on .main-menu {
    display: block;
    width: 100%;
    margin-left: auto;
    padding: 30px;
    height: auto;
    min-height: auto;
  }

  .site-menu.profile-card-on .main-menu {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 480px) {
  .elementor
    .elementor-widget:not(.elementor-widget-text-editor)
    .portfolio-grid
    .item,
  .elementor
    .elementor-widget:not(.elementor-widget-text-editor)
    .portfolio-grid.two-columns
    .item,
  .elementor
    .elementor-widget:not(.elementor-widget-text-editor)
    .portfolio-grid.three-columns
    .item,
  .elementor
    .elementor-widget:not(.elementor-widget-text-editor)
    .portfolio-grid.four-columns
    .item,
  .elementor
    .elementor-widget:not(.elementor-widget-text-editor)
    .portfolio-grid.five-columns
    .item,
  .portfolio-grid .item,
  .portfolio-grid.two-columns .item,
  .portfolio-grid.three-columns .item,
  .portfolio-grid.four-columns .item,
  .portfolio-grid.five-columns .item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .portfolio-grid {
    margin-left: 0;
    margin-right: 0;
  }

  .nothing-found h2 {
    font-size: 60px;
  }

  .entry-title {
    font-size: 27px;
  }

  .controls.two-columns .left-column {
    width: 100%;
    float: none;
    margin-right: 0;
  }

  .controls.two-columns .right-column {
    width: 100%;
    float: none;
  }

  .comment-list .children {
    margin-left: 7px;
    padding-left: 5px;
  }

  .entry-meta.entry-meta-bottom .date-author > span {
    display: block;
  }

  .entry-meta.entry-meta-bottom .date-author > span:after {
    display: none;
  }

  .post-navigation .nav-link-prev,
  .post-navigation .nav-link-next {
    float: none;
    width: 100%;
  }
}

/* =============================================================================
15. Elements
============================================================================= */
/* Home Page First Style */
.home-page-first-style {
  min-height: calc(80vh - 140px);
}

.home-page-first-style .title-block h2 {
  font-size: 60px;
  line-height: 74px;
  margin: 0;
  text-align: center;
}

.home-page-first-style .title-block .sp-subtitle {
  color: #888;
  font-size: 21px;
  font-weight: 300;
  margin: 5px 0;
  text-align: center;
}

.text-rotation {
  display: block;
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .home-page-first-style .title-block h2 {
    font-size: 48px;
    line-height: 56px;
    margin: 0 0 10px;
    text-align: center;
  }
}
/* /Home Page First Style */

/* Skills First Style */
.skills-info.skills-first-style {
  margin-bottom: 30px;
}

.skills-info.skills-first-style h4 {
  font-size: 1em;
  line-height: 1.1em;
  position: relative;
  float: left;
  margin: 0 0 8px;
}

.skills-first-style .skill-container {
  position: relative;
  background-color: #d6d7d8;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 4px;
  margin-bottom: 17px;
  width: 100%;
}

.skills-first-style .skill-value {
  font-size: 12px;
  line-height: 1.1em;
  position: relative;
  float: right;
  margin: 0 0 4px;
  color: #666768;
}

.skills-first-style .skill-percentage {
  position: absolute;
  background-color: #4dba87;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  height: 6px;
  padding: 0;
  top: -1px;
}

/* Services */
.info-block-w-icon {
  margin-bottom: 25px;
}

.info-block-w-icon .ci-icon {
  display: table-cell;
  width: 54px;
  padding-right: 22px;
}

.info-block-w-icon i {
  position: relative;
  font-size: 28px;
  background: #f5f6f7;
  display: block;
  padding: 12px;
  border-radius: 10px;
}

.info-block-w-icon .ci-text {
  position: relative;
  display: table-cell;
  padding: 16px 0 0;
  vertical-align: middle;
}

.info-block-w-icon .ci-text h4 {
  margin: 0 0 10px;
  font-size: 18px;
}

.info-block-w-icon .ci-text p {
  font-size: 0.9em;
}

/* Info List */
.info-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-list ul li {
  margin-bottom: 10px;
}

.info-list .title {
  color: #4dba87;
  margin-right: 5px;
  font-weight: 600;
}

/* Testimonials Slider */
.testimonials:not(.owl-loaded) {
  display: block;
  position: relative;
  height: auto;
  min-height: 1px;
}

.testimonial-item {
  padding: 30px;
  margin-bottom: 10px;
}

.testimonial-content {
  position: relative;
  padding: 30px 20px 20px 60px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.testimonial-picture {
  position: absolute;
  display: block;
  top: -30px;
  left: -30px;
  width: 80px;
  height: 80px;
}

.testimonial-picture img {
  display: inline-block;
  border-radius: 80px;
}

.testimonial-text p {
  font-style: italic;
}

.testimonial-author-info {
  margin-top: 18px;
}

.testimonial-author {
  margin: 0 0 2px;
}

.testimonial-firm {
  margin: 0;
  font-size: 0.8em;
  color: #bbb;
}

.testimonial-icon {
  display: block;
  line-height: 20px;
  font-size: 20px;
  position: absolute;
  left: 20px;
  bottom: 46px;
  color: #e5e5e5;
}

.testimonial-icon-big {
  display: block;
  line-height: 44px;
  font-size: 44px;
  position: absolute;
  right: -24px;
  bottom: -24px;
  color: #f5f5f5;
  z-index: -1;
}
/* /Testimonials Slider */

/* --- Sliders Nav --- */
.testimonials.owl-carousel .owl-nav,
.clients.owl-carousel .owl-nav {
  position: relative;
  text-align: right;
}

.testimonials.owl-carousel .owl-nav .owl-prev,
.testimonials.owl-carousel .owl-nav .owl-next,
.clients.owl-carousel .owl-nav .owl-prev,
.clients.owl-carousel .owl-nav .owl-next {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #e6e7e8;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  font-family: 'Linearicons-Free';
  font-size: 12px;
  font-weight: 700;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  color: #555;
}

.testimonials.owl-carousel .owl-nav .owl-prev,
.clients.owl-carousel .owl-nav .owl-prev {
  margin-right: 3px;
}

.testimonials.owl-carousel .owl-nav .owl-prev:before,
.testimonials.owl-carousel .owl-nav .owl-next:before,
.clients.owl-carousel .owl-nav .owl-prev:before,
.clients.owl-carousel .owl-nav .owl-next:before {
  position: relative;
  margin: 2px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  display: block;
  cursor: pointer;
  color: inherit;
  z-index: 1;
}

.testimonials.owl-carousel .owl-nav .owl-prev:before,
.clients.owl-carousel .owl-nav .owl-prev:before {
  content: '\e875';
}

.testimonials.owl-carousel .owl-nav .owl-next:before,
.clients.owl-carousel .owl-nav .owl-next:before {
  content: '\e876';
}

.testimonials.owl-carousel .owl-nav .owl-prev:hover,
.testimonials.owl-carousel .owl-nav .owl-next:hover,
.clients.owl-carousel .owl-nav .owl-prev:hover,
.clients.owl-carousel .owl-nav .owl-next:hover {
  background-color: #4dba87;
  border-color: #4dba87;
  color: #fff;
}
/* --- End of Sliders Nav --- */

/* --- Clients --- */
.clients:not(.owl-loaded) {
  display: block;
  position: relative;
  height: auto;
  min-height: 1px;
}

.clients.owl-carousel .client-block {
  padding: 15px;
  margin-bottom: 15px;
}

.client-block {
  text-align: center;
  padding: 5px 0;
  margin-bottom: 30px;
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.client-block img {
  max-width: 90%;
}
/* --- End of Clients --- */

/* --- Fun Fact --- */
.fun-fact {
  position: relative;
  text-align: left;
  width: 100%;
  display: block;
  margin: 0 0 15px;
  border: 2px solid #e6e7e8;
  padding: 20px 25px 20px 85px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.fun-fact:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
  box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
}

.fun-fact.gray-bg {
  background-color: #fcfcfc;
}

.fun-fact h4 {
  font-size: 16px;
  margin: 0 0 10px;
}

.fun-fact .fun-fact-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 60px;
  top: 0;
  left: 0;
  background-color: #f5f6f7;
}

.fun-fact i {
  position: relative;
  font-size: 28px;
  color: #858687;
  margin-bottom: 6px;
  width: 28px;
  height: 28px;
  text-align: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.fun-fact .lm-info-block {
  font-size: 24px;
  font-weight: 400;
  display: block;
  margin: 10px 0;
  color: #4dba87;
}

.fun-fact-block-value {
  color: #a5a6a7;
  font-size: 30px;
  font-weight: 600;
  line-height: 50px;
  display: block;
}

.fun-fact-block-text {
  display: block;
}
/* --- /Fun Fact --- */

/* Timeline */
.timeline-item {
  position: relative;
  padding: 25px 0 20px 40px;
}

.timeline-item:before {
  content: '';
  position: absolute;
  display: block;
  height: 100%;
  background-color: #f5f6f9;
  width: 1px;
  left: 15px;
  bottom: 5px;
}

.timeline-item:after {
  content: '';
  position: absolute;
  display: block;
  height: 1px;
  background-color: #f5f6f9;
  width: calc(100% - 15px);
  left: 15px;
  bottom: 3px;
}

.timeline-item:first-child {
  padding-top: 3px;
}

.timeline-item:last-child:after {
  display: none;
}

.timeline-item:last-child {
  margin-bottom: 30px;
}

.timeline-item h5.item-period {
  display: inline-block;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 5px -40px;
  color: #222;
  background-color: #fff;
  padding: 0 10px;
  line-height: 23px;
  border: 2px solid #a6a7a8;
  border-radius: 3px;
}

.timeline-item .item-company {
  display: inline-block;
  font-size: 13px;
  color: #9e9e9e;
  margin-left: 7px;
}

.timeline-item .item-title {
  font-size: 16px;
  margin: 10px 0;
}

.timeline-item p {
  font-size: 0.9em;
}
/* /Timeline */

/* --- Certificates --- */
.certificate-item {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  border: 2px solid #e6e7e8;
  border-radius: 6px;
  margin-bottom: 20px;
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

a > .certificate-item:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
  box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
}

.certi-logo {
  display: table-cell;
  width: 120px;
  height: 100%;
  background-color: #f7f7f7;
  padding: 5px;
  vertical-align: middle;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.certi-logo img {
  display: block;
  max-width: 100%;
}

.certi-content {
  display: table-cell;
  width: 100%;
  padding: 20px 25px;
  vertical-align: middle;
}

.certi-title h4 {
  font-size: 16px;
  margin: 0 0 10px;
}

.certi-id {
  font-size: 0.95em;
  color: #555;
}

.certi-date {
  font-size: 0.85em;
  color: #9e9e9e;
  opacity: 1;
  line-height: 1;
  margin-top: 5px;
}
/* --- End of Certificates --- */

/* --- Knowledges --- */
.knowledges {
  list-style: none;
  margin: 0;
  padding: 0;
}

.knowledges li {
  display: inline-block;
  background-color: #4dba87;
  border-radius: 3px;
  color: #fff;
  padding: 5px 10px;
  line-height: 1.2;
  margin: 3px 2px;
  font-size: 13px;
}
/* --- End of Knowledges --- */

/* Pricing */
.fw-pricing .fw-package {
  background-color: #fff;
  border: 2px solid #e6e7e8;
  padding: 40px;
  margin-bottom: 10px;
  border-radius: 8px;
  text-align: left;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.fw-pricing .fw-package.highlight {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.fw-pricing .fw-package:hover,
.fw-pricing .fw-package.highlight:hover {
  -webkit-transform: translateY(-9px);
  -ms-transform: translateY(-9px);
  -o-transform: translateY(-9px);
  transform: translateY(-9px);
  -webkit-box-shadow: 0 18px 24px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 18px 24px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 18px 24px rgba(0, 0, 0, 0.15);
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.15);
}

.fw-pricing .fw-pricing-row {
  padding: 30px 0 20px;
}

.fw-pricing .fw-pricing-row span {
  display: inline-block;
  line-height: 1;
  color: #333;
  font-size: 36px;
  font-weight: 600;
  margin-right: 5px;
}

.fw-pricing .fw-pricing-row small {
  color: #a6a7a8;
  font-weight: 300;
  font-size: 0.9em;
  display: inline-block;
}

.fw-pricing .fw-heading-row h4 {
  font-size: 18px;
  margin: 5px 0 0;
}

.fw-pricing .fw-default-row {
  padding: 5px 0;
  font-size: 14px;
}

.fw-pricing .fw-button-row {
  padding: 10px 0 20px;
}

.fw-pricing .fw-price-icon-no:before {
  content: '\f068';
  color: #e87878;
}

.fw-pricing .fw-price-icon-yes:before {
  content: '\f058';
  color: #ade878;
}
/* /Pricing */

/* --- Info Block with Borders --- */
.lm-info-block {
  position: relative;
  text-align: center;
  width: 100%;
  display: block;
  margin: 0 0 15px 0;
  background-color: #fff;
  padding: 25px 10px;
  border-radius: 8px;
  border: 2px solid #e6e7e8;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.lm-info-block i {
  position: relative;
  font-size: 33px;
  color: #4dba87;
  z-index: 1;
}

.lm-info-block h4 {
  font-size: 15px;
  margin: 8px 0 0;
}

.lm-info-block .lm-info-block-value {
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  display: block;
  margin: 10px 0;
  color: #4dba87;
}

.lm-info-block .lm-info-block-value:empty {
  margin: 0;
}
/* --- End of Info Block with Borders --- */

@media only screen and (max-width: 480px) {
  .timeline-second-style .timeline-item {
    position: relative;
    display: block;
  }

  .timeline-second-style .left-part {
    width: 100%;
    display: block;
    padding-left: 25px;
    min-height: 100%;
    text-align: left;
  }

  .timeline-second-style .divider {
    left: 0;
  }

  .timeline-second-style .right-part {
    width: 100%;
    display: block;
    padding-left: 25px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 500px) {
  .testimonials.owl-carousel .owl-item {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .testimonials.owl-carousel .owl-item.active {
    opacity: 1;
    visibility: visible;
  }

  .testimonials.owl-carousel .owl-stage-outer {
    width: calc(100% + 10px);
    padding: 0 5px;
    margin-left: -5px;
  }

  .testimonial-item {
    padding: 30px 0;
  }

  .testimonial-content {
    position: relative;
    padding: 65px 20px 20px 20px;
  }

  .testimonial-icon {
    top: 18px;
  }

  .testimonial-icon-big {
    right: 16px;
    bottom: 18px;
    color: #e6e7e8;
    z-index: 1;
    font-size: 40px;
  }

  .testimonial-picture {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
</style>
