<template>
  <section>
    <div class="row">
      <div class="col-xs-12 col-sm-12">
        <div class="block-title">
          <h2>Was ich kann</h2>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="info-list-w-icon">
          <div class="info-block-w-icon">
            <div class="ci-icon">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-what-i-do"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <metadata>
                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                </metadata>
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M1535 5096 c-13 -13 -26 -39 -29 -57 -18 -116 -13 -108 -59 -119 -80
-20 -163 -53 -257 -101 l-94 -48 -52 39 c-43 34 -58 40 -98 40 l-48 0 -139
-136 c-150 -148 -169 -179 -149 -236 6 -18 25 -50 43 -71 l33 -38 -58 -117
c-32 -64 -68 -154 -80 -198 l-23 -82 -59 -7 c-73 -8 -108 -25 -124 -60 -8 -17
-12 -92 -12 -225 0 -198 0 -199 25 -228 25 -30 39 -35 126 -47 45 -6 45 -6 59
-58 17 -69 79 -219 118 -286 l31 -54 -34 -41 c-42 -51 -58 -91 -50 -126 4 -17
61 -82 154 -173 142 -141 149 -147 188 -147 32 0 52 8 86 34 l45 35 7 -87 c16
-206 69 -398 165 -598 148 -308 372 -559 678 -760 76 -51 123 -90 147 -122 63
-85 65 -101 65 -544 0 -425 2 -442 49 -468 28 -14 1205 -14 1233 1 45 24 48
52 48 494 l0 415 28 0 c15 0 153 -14 307 -31 155 -17 307 -29 340 -26 133 10
248 109 283 242 6 22 18 153 27 290 9 138 18 260 21 272 3 16 34 36 128 82
130 63 152 83 176 155 25 76 13 108 -150 394 l-152 269 1 129 c9 724 -380
1335 -1005 1583 -157 62 -293 93 -517 116 l-59 7 21 22 c12 13 25 36 30 53 19
67 5 87 -191 272 -101 95 -109 101 -149 101 -34 0 -53 -8 -94 -39 l-52 -39
-94 48 c-52 26 -132 60 -179 75 -132 41 -130 40 -130 79 0 58 -11 95 -38 121
l-25 25 -219 0 c-217 0 -219 0 -243 -24z m325 -208 c0 -48 18 -103 39 -122 11
-10 65 -29 121 -42 124 -31 237 -77 333 -138 40 -24 84 -47 98 -51 29 -7 78
12 122 47 l27 22 52 -52 52 -52 -22 -27 c-35 -44 -54 -93 -47 -122 3 -14 24
-53 45 -86 73 -113 160 -334 160 -407 0 -34 63 -88 102 -88 74 0 78 -5 78 -85
l0 -74 -57 -7 c-32 -3 -66 -11 -75 -16 -25 -12 -43 -52 -61 -135 -24 -106 -71
-220 -134 -325 -69 -113 -73 -147 -25 -207 17 -22 32 -44 32 -48 0 -5 -22 -30
-49 -57 l-49 -48 -52 36 c-69 48 -96 46 -184 -9 -109 -68 -215 -113 -337 -144
-145 -36 -169 -59 -169 -162 l0 -29 -79 0 -79 0 -7 55 c-10 86 -31 103 -162
136 -118 29 -251 86 -351 151 -53 35 -68 40 -101 35 -24 -3 -56 -18 -81 -38
l-41 -33 -54 54 -54 53 36 44 c49 59 49 104 0 177 -72 109 -138 267 -161 386
-18 91 -41 112 -133 125 l-53 7 0 73 c0 80 4 85 77 85 58 0 91 42 113 140 28
128 81 254 157 368 47 70 48 117 4 172 -17 21 -31 43 -31 47 0 5 22 31 50 58
l50 49 27 -22 c44 -35 93 -54 122 -47 14 4 57 26 96 50 106 66 198 105 325
136 138 34 156 49 166 139 l7 60 78 0 79 0 0 -32z m1325 -740 c470 -108 837
-442 999 -906 63 -180 79 -289 81 -527 l2 -210 140 -245 c77 -135 145 -255
152 -268 12 -22 8 -25 -123 -88 -103 -49 -139 -71 -150 -92 -9 -18 -21 -128
-35 -335 -12 -170 -25 -316 -28 -325 -11 -32 -56 -72 -87 -78 -17 -3 -201 13
-408 35 -404 44 -421 43 -452 -4 -14 -21 -16 -82 -16 -465 l0 -440 -455 0
-455 0 0 353 c0 389 -4 420 -62 530 -46 85 -111 153 -208 215 -347 223 -598
534 -715 885 -36 110 -65 237 -65 288 0 26 3 30 18 24 9 -5 50 -18 89 -30 40
-11 76 -24 80 -28 4 -4 10 -35 14 -70 6 -52 12 -66 38 -89 l31 -28 210 0 210
0 30 28 c26 23 32 37 38 91 4 41 12 67 22 72 8 4 55 20 104 35 50 15 132 49
184 76 52 26 100 45 106 41 6 -4 30 -22 54 -40 33 -25 54 -33 84 -33 40 0 47
6 181 138 77 75 145 151 153 168 18 42 5 83 -41 139 l-35 44 34 58 c38 66 99
215 116 285 12 46 13 47 59 53 85 11 98 16 122 41 24 25 24 28 24 241 l0 215
-32 28 c-25 22 -45 30 -95 35 l-64 7 -26 86 c-15 48 -30 96 -34 106 -7 18 -3
19 57 12 35 -4 105 -16 154 -28z"
                  />
                  <path
                    d="M1675 4409 c-297 -46 -532 -254 -606 -536 -26 -100 -29 -259 -5 -358
49 -204 203 -395 391 -485 201 -96 457 -95 651 3 258 130 404 367 404 655 0
127 -18 208 -70 317 -116 241 -346 392 -625 409 -44 3 -107 1 -140 -5z m185
-209 c95 -15 219 -79 288 -149 161 -161 198 -401 94 -606 -31 -63 -151 -182
-218 -217 -196 -105 -450 -67 -609 90 -172 171 -208 425 -89 628 77 131 218
228 367 253 73 12 93 12 167 1z"
                  />
                </g>
              </svg>
            </div>
            <div class="ci-text">
              <h4>Produktentwicklung</h4>
              <p>
                Als studierter Ingenieur habe ich ein umfangreiches technisches
                Wissen. Durch zahlreiche Patente ist belegt, dass ich dieses
                Wissen lösungsorientiert anwenden kann.
              </p>
            </div>
          </div>
        </div>
        <div class="info-list-w-icon">
          <div class="info-block-w-icon">
            <div class="ci-icon">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-what-i-do"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <metadata>
                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                </metadata>
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M2414 5092 c-118 -57 -179 -166 -172 -307 7 -130 67 -218 185 -272
l53 -24 0 -354 0 -353 -82 -12 c-106 -15 -271 -69 -371 -123 -44 -23 -117 -72
-162 -108 l-83 -67 -116 117 -116 116 0 232 0 231 23 6 c35 8 115 83 139 129
64 126 12 296 -111 359 -45 24 -65 28 -131 28 -66 0 -86 -4 -131 -28 -60 -31
-111 -96 -130 -165 -15 -56 -6 -144 19 -194 24 -46 104 -121 139 -129 l23 -6
0 -209 0 -209 -165 0 -165 0 -10 28 c-20 54 -36 78 -81 123 -64 65 -139 92
-239 87 -93 -6 -154 -32 -213 -91 -63 -63 -90 -138 -85 -242 5 -131 63 -220
177 -274 78 -36 202 -37 276 -1 65 32 121 90 152 156 l24 54 187 0 187 0 118
-118 c64 -64 117 -120 117 -124 0 -3 -16 -30 -36 -59 -85 -127 -145 -279 -171
-439 l-16 -100 -392 0 -392 0 -18 40 c-24 53 -89 112 -148 135 -61 23 -165 17
-223 -13 -94 -48 -153 -161 -140 -270 13 -113 84 -196 195 -228 126 -37 262
24 319 143 l16 33 392 0 392 0 12 -82 c24 -161 96 -342 188 -473 100 -142 248
-275 306 -275 44 0 76 35 76 82 0 31 -8 42 -72 98 -244 210 -361 473 -345 768
7 133 35 240 92 360 57 118 92 170 179 261 122 126 252 205 422 257 81 25 104
27 249 28 146 0 168 -2 250 -27 384 -118 650 -431 696 -823 24 -199 -33 -430
-152 -615 -16 -25 -29 -53 -29 -62 0 -31 23 -66 51 -77 49 -21 76 -6 126 72
82 126 145 302 159 444 l7 64 397 0 397 0 18 -40 c21 -48 82 -105 134 -128 53
-23 165 -21 221 4 100 45 155 134 154 249 0 60 -4 79 -30 125 -36 64 -97 115
-157 131 -55 15 -153 7 -198 -15 -49 -23 -105 -80 -126 -128 l-17 -38 -396 0
-397 0 -7 69 c-12 139 -80 321 -169 459 l-45 69 122 122 121 121 186 0 186 0
17 -44 c24 -64 87 -130 154 -163 49 -24 69 -28 142 -28 73 0 93 4 142 28 65
32 121 89 152 156 30 67 30 197 -2 266 -30 65 -92 128 -156 157 -41 18 -69 23
-141 23 -78 0 -97 -4 -140 -27 -64 -33 -125 -99 -151 -161 l-19 -47 -162 0
-163 0 0 209 0 209 32 13 c89 37 152 135 152 239 0 161 -109 270 -269 270
-160 0 -266 -106 -266 -266 0 -111 65 -212 159 -246 l32 -12 0 -226 0 -225
-120 -120 c-91 -91 -123 -117 -134 -110 -7 6 -40 32 -72 58 -149 122 -344 203
-556 232 l-48 6 0 358 0 358 39 12 c56 16 143 99 172 163 34 77 33 192 -3 269
-30 65 -81 120 -141 151 -32 17 -64 23 -137 25 -88 4 -99 2 -156 -25z m181
-133 c55 -14 109 -70 119 -122 15 -82 -38 -164 -122 -186 -125 -34 -233 104
-176 224 20 43 44 64 91 81 47 16 36 16 88 3z m-1046 -466 c30 -32 33 -40 28
-77 -8 -57 -52 -96 -108 -96 -55 0 -109 51 -109 103 0 62 72 123 130 110 14
-3 40 -21 59 -40z m2173 11 c39 -36 40 -113 2 -156 -23 -24 -72 -37 -109 -27
-100 28 -101 181 -2 207 37 10 82 0 109 -24z m-2902 -693 c44 -23 76 -69 85
-122 16 -92 -77 -186 -174 -176 -149 16 -194 220 -65 294 48 28 107 29 154 4z
m3630 -3 c57 -39 75 -72 75 -137 0 -73 -33 -121 -99 -146 -53 -20 -98 -15
-147 18 -126 84 -62 287 91 287 32 0 57 -7 80 -22z m-3974 -1062 c24 -24 34
-43 34 -65 0 -104 -113 -156 -184 -85 -69 70 -22 184 77 184 31 0 46 -7 73
-34z m4295 18 c36 -18 63 -75 54 -113 -4 -14 -18 -38 -32 -53 -68 -72 -183
-24 -183 75 0 31 7 46 34 73 39 39 77 44 127 18z"
                  />
                  <path
                    d="M2383 3360 c-162 -42 -221 -92 -193 -160 22 -54 72 -65 140 -30 112
57 288 64 414 16 222 -84 361 -285 359 -521 -1 -142 -47 -258 -147 -364 -46
-48 -125 -111 -140 -111 -3 0 -6 89 -6 198 0 145 -4 212 -15 248 -55 187 -279
252 -420 123 -80 -74 -85 -94 -85 -342 0 -119 -3 -217 -6 -217 -16 0 -98 66
-137 111 -127 145 -171 362 -107 534 34 94 36 122 9 151 -30 32 -90 33 -118 3
-10 -11 -32 -60 -48 -107 -25 -79 -28 -100 -28 -222 1 -125 3 -142 32 -223 43
-122 86 -190 178 -282 58 -58 99 -89 152 -115 l72 -35 0 -370 c1 -315 -1 -367
-13 -355 -7 8 -60 71 -116 138 -120 146 -161 172 -271 172 -147 0 -259 -112
-259 -260 0 -87 20 -115 356 -518 l324 -387 0 -201 c0 -182 2 -202 18 -217 27
-25 94 -22 118 4 17 19 19 41 22 234 3 171 1 219 -11 246 -9 19 -156 201 -327
404 -171 204 -319 384 -327 401 -31 63 16 134 89 134 49 0 51 -1 265 -262 172
-209 192 -225 242 -197 52 29 51 9 51 841 l0 776 25 30 c32 39 67 47 110 27
66 -32 65 -22 65 -627 0 -506 1 -546 18 -560 27 -25 61 -31 94 -17 39 16 48
43 48 145 l0 84 74 0 74 0 4 -95 c4 -110 21 -139 82 -139 63 0 76 23 76 136
l0 98 80 0 80 0 0 -89 c0 -49 3 -97 6 -106 10 -25 62 -48 93 -40 54 14 61 29
61 138 l0 99 28 -6 c46 -11 104 -59 129 -105 20 -39 23 -58 23 -156 0 -119 11
-169 40 -185 31 -17 79 -11 101 11 20 20 21 30 17 177 -3 145 -5 161 -30 214
-34 73 -103 142 -176 176 l-57 27 -352 3 -353 3 0 82 0 82 73 38 c176 90 304
248 358 442 29 102 31 269 6 364 -67 250 -267 446 -521 511 -102 26 -244 26
-343 0z"
                  />
                  <path
                    d="M3652 947 c-15 -16 -22 -36 -22 -63 -1 -132 -62 -273 -167 -386 l-73
-78 0 -183 c0 -171 1 -186 21 -211 16 -21 29 -26 63 -26 72 0 76 11 76 194 l0
158 55 59 c103 112 169 267 181 422 6 74 4 85 -16 110 -28 36 -86 38 -118 4z"
                  />
                </g>
              </svg>
            </div>

            <div class="ci-text">
              <h4>Digitalisierung</h4>
              <p>
                Mit Kenntnissen in der Sensor- und Messtechnik bin ich in der
                Lage Maschinen und Anlagen oder ganze Prozesse zu digitalisieren
                und damit besser zu verstehen.
              </p>
            </div>
          </div>
        </div>
        <div class="info-list-w-icon">
          <div class="info-block-w-icon">
            <div class="ci-icon">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-what-i-do"
                viewBox="0 0 225.000000 225.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <metadata>
                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                </metadata>
                <g
                  transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M2033 2229 c-68 -34 -98 -116 -69 -189 4 -10 -56 -78 -191 -212
l-198 -198 -37 12 c-29 9 -47 9 -75 0 l-38 -12 -80 80 -80 80 0 75 c0 73 -1
76 -37 111 -29 30 -45 38 -86 42 -99 9 -162 -49 -162 -149 l0 -54 -364 -364
-363 -363 -31 16 c-73 38 -168 8 -204 -64 -50 -98 21 -210 132 -210 88 0 155
72 147 156 l-4 42 366 366 367 367 33 -17 c25 -12 48 -15 90 -11 l57 6 82 -82
81 -82 -12 -42 c-9 -36 -8 -49 9 -90 15 -39 28 -52 64 -70 24 -13 56 -23 70
-23 14 0 46 10 70 23 36 18 49 31 64 70 17 41 18 54 8 90 l-11 42 197 198
c135 135 202 195 212 191 74 -29 156 1 189 70 12 23 21 53 21 66 0 48 -39 108
-84 129 -53 26 -80 26 -133 0z m121 -79 c26 -25 25 -76 -2 -102 -45 -45 -125
-13 -125 51 0 70 75 100 127 51z m-979 -225 c14 -13 25 -36 25 -50 0 -33 -42
-75 -75 -75 -14 0 -37 11 -50 25 -34 33 -33 77 1 104 35 28 69 26 99 -4z m379
-375 c26 -25 25 -76 -2 -102 -45 -45 -125 -13 -125 51 0 70 75 100 127 51z
m-1365 -510 c10 -5 24 -22 30 -39 36 -85 -92 -142 -135 -60 -35 68 37 136 105
99z"
                  />
                  <path
                    d="M1735 1258 c-3 -7 -6 -276 -8 -598 l-2 -585 -37 -3 -38 -3 0 328 c0
243 -3 332 -12 341 -8 8 -54 12 -140 12 -116 0 -128 -2 -138 -19 -6 -13 -10
-139 -10 -341 l0 -321 -37 3 -38 3 -5 445 -5 445 -140 0 -140 0 -5 -445 -5
-445 -37 -3 -38 -3 0 360 c0 197 -3 366 -6 375 -9 24 -279 24 -288 0 -3 -9 -6
-178 -6 -375 l0 -360 -37 3 -38 3 -5 220 -5 220 -140 0 -140 0 -5 -220 -5
-220 -107 -3 c-97 -2 -107 -5 -113 -23 -13 -41 9 -49 144 -49 85 0 131 4 139
12 9 9 12 73 12 225 l0 213 75 0 75 0 0 -213 c0 -152 3 -216 12 -225 13 -13
163 -17 192 -6 14 5 16 48 16 375 l0 369 80 0 80 0 0 -369 c0 -327 2 -370 16
-375 29 -11 179 -7 192 6 9 9 12 123 12 450 l0 438 75 0 75 0 0 -438 c0 -327
3 -441 12 -450 8 -8 46 -12 105 -12 81 0 94 2 103 19 6 13 10 139 10 341 l0
321 73 -3 72 -3 3 -329 c2 -249 5 -332 15 -338 20 -13 181 -10 195 4 9 9 12
156 12 600 l0 588 75 0 75 0 0 -588 c0 -444 3 -591 12 -600 8 -8 54 -12 139
-12 135 0 157 8 144 49 -6 18 -16 21 -113 23 l-107 3 -5 595 -5 595 -138 3
c-104 2 -139 0 -142 -10z"
                  />
                </g>
              </svg>
            </div>
            <div class="ci-text">
              <h4>Datenanalyse und Statistik</h4>
              <p>
                Maschinen- und Prozessfreigaben mittels Fähigkeitsindex sowie
                statistische Prozesslenkung mittels Regelkarten beherrsche ich
                sicher.
              </p>
            </div>
          </div>
        </div>
        <div class="info-list-w-icon">
          <div class="info-block-w-icon">
            <div class="ci-icon">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-what-i-do"
                viewBox="0 0 225.000000 225.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <metadata>
                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                </metadata>
                <g
                  transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M115 2231 c-48 -22 -79 -54 -100 -103 -19 -47 -22 -297 -3 -316 9 -9
254 -12 1039 -12 1105 0 1061 -2 1044 49 -6 21 -9 21 -1013 23 l-1007 3 -3
100 c-3 118 12 160 68 187 33 17 101 18 985 18 884 0 952 -1 985 -18 74 -37
70 25 70 -1035 0 -1029 2 -996 -55 -1033 -35 -23 -32 -68 6 -72 31 -4 87 50
105 100 9 25 13 276 13 1001 1 852 -1 972 -14 1005 -21 49 -52 81 -100 103
-38 18 -88 19 -1010 19 -922 0 -972 -1 -1010 -19z"
                  />
                  <path
                    d="M256 2079 c-36 -28 -36 -80 0 -108 15 -12 34 -21 44 -21 34 0 70 39
70 75 0 36 -36 75 -70 75 -10 0 -29 -9 -44 -21z"
                  />
                  <path
                    d="M475 2075 c-14 -13 -25 -36 -25 -50 0 -33 42 -75 75 -75 33 0 75 42
75 75 0 14 -11 37 -25 50 -13 14 -36 25 -50 25 -14 0 -37 -11 -50 -25z"
                  />
                  <path
                    d="M702 2077 c-31 -33 -29 -80 4 -106 15 -12 34 -21 44 -21 34 0 70 39
70 75 0 24 -7 39 -26 54 -35 27 -65 27 -92 -2z"
                  />
                  <path
                    d="M5 1698 c-3 -13 -4 -372 -3 -799 l3 -775 24 -36 c13 -19 40 -46 59
-59 l36 -24 925 -3 c509 -1 936 0 949 3 l22 5 0 514 c0 459 -2 515 -16 520
-20 8 -238 8 -258 0 -14 -5 -16 -54 -16 -444 0 -433 0 -439 20 -445 50 -16 50
-12 50 421 l0 404 75 0 75 0 0 -455 0 -455 -885 0 c-965 0 -934 -2 -972 56
-17 26 -18 74 -23 809 l-5 780 -27 3 c-22 3 -29 -2 -33 -20z"
                  />
                  <path
                    d="M242 1641 c-8 -4 -12 -19 -10 -32 l3 -24 177 -3 c171 -2 177 -2 183
18 14 44 -6 50 -179 50 -88 0 -167 -4 -174 -9z"
                  />
                  <path
                    d="M1362 1338 c-9 -9 -12 -154 -12 -589 0 -619 -1 -610 49 -594 21 6 21
13 23 563 l3 557 75 0 75 0 3 -557 c2 -550 2 -557 23 -563 50 -16 49 -25 49
594 0 435 -3 580 -12 589 -8 8 -53 12 -138 12 -85 0 -130 -4 -138 -12z"
                  />
                  <path
                    d="M612 1038 c-9 -9 -12 -120 -12 -439 0 -457 0 -460 49 -444 20 6 21
14 23 413 l3 407 75 0 75 0 3 -407 c2 -399 3 -407 23 -413 49 -16 49 -13 49
444 0 319 -3 430 -12 439 -17 17 -259 17 -276 0z"
                  />
                  <path
                    d="M993 743 c-10 -3 -13 -72 -13 -293 0 -282 0 -289 20 -295 47 -15 50
0 50 271 l0 254 75 0 75 0 0 -254 c0 -271 3 -286 50 -271 20 6 20 13 20 295 0
253 -2 289 -16 294 -19 7 -243 7 -261 -1z"
                  />
                  <path
                    d="M243 443 c-10 -3 -13 -42 -13 -143 0 -131 1 -139 20 -145 41 -13 50
10 50 121 l0 104 75 0 75 0 0 -104 c0 -111 9 -134 50 -121 19 6 20 14 20 145
0 116 -2 139 -16 144 -19 7 -243 7 -261 -1z"
                  />
                </g>
              </svg>
            </div>
            <div class="ci-text">
              <h4>Frontend-Entwicklung</h4>
              <p>
                Mit fundierten Kenntnissen in JavaScript Frameworks schaffe ich
                es aus unterschiedlichen Daten Informationen zu generieren und
                diese verständlich darzustellen.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="info-list-w-icon">
          <div class="info-block-w-icon">
            <div class="ci-icon">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-what-i-do"
                viewBox="0 0 860.000000 927.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <metadata>
                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                </metadata>
                <g
                  transform="translate(0.000000,927.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M4165 8855 c-266 -47 -517 -160 -656 -295 -136 -133 -168 -276 -96
-434 48 -104 200 -233 357 -304 135 -60 331 -114 463 -127 l67 -7 0 -241 0
-242 -298 -298 -297 -297 -1033 0 -1032 0 0 -530 0 -530 -468 -2 -467 -3 -3
-662 -2 -663 1070 0 1070 0 0 265 0 265 730 0 730 0 0 -465 0 -465 -1397 -2
-1398 -3 -3 -267 -2 -268 -130 0 -130 0 0 -109 c0 -126 -9 -231 -21 -231 -4 0
-63 53 -129 117 l-121 117 -275 -275 c-151 -150 -274 -277 -274 -281 0 -4 24
-31 53 -60 109 -108 180 -188 173 -196 -4 -4 -83 -9 -174 -12 l-167 -5 0 -395
0 -395 165 -3 c90 -1 169 -7 174 -12 5 -5 -37 -56 -108 -128 -64 -65 -116
-122 -116 -127 0 -4 124 -132 275 -283 l276 -275 118 116 c65 64 122 117 128
117 13 0 23 -97 23 -231 l0 -109 400 0 400 0 0 169 c0 94 4 172 8 175 4 2 62
-47 127 -109 l120 -113 277 276 277 276 -116 119 c-87 90 -112 121 -102 128 8
5 87 9 177 9 l162 0 0 400 0 400 -152 1 c-231 2 -225 -8 -81 140 l122 125
-279 279 -280 279 -118 -117 c-65 -64 -124 -117 -130 -117 -9 0 -12 48 -12
170 l0 170 -135 0 -135 0 0 135 0 135 2665 0 2665 0 0 -138 0 -138 -97 -22
c-287 -65 -582 -301 -720 -577 -80 -158 -119 -334 -110 -502 11 -240 111 -477
289 -684 85 -98 113 -148 103 -179 -5 -16 -18 -20 -68 -22 l-62 -3 -3 -132 -3
-132 68 -3 68 -3 3 -197 2 -198 665 0 665 0 0 200 0 200 65 0 65 0 0 135 0
134 -62 3 -63 3 -3 42 c-3 41 0 47 65 115 236 252 351 559 321 858 -35 356
-268 690 -593 852 -94 47 -197 85 -267 98 l-58 11 -2 272 -3 272 -1397 3
-1398 2 0 465 0 465 730 0 730 0 0 -265 0 -265 1065 0 1065 0 0 665 0 665
-465 0 -465 0 0 530 0 530 -1032 0 -1033 0 -297 297 -298 298 0 205 c0 113 3
221 6 241 6 37 6 37 68 44 445 50 827 299 852 556 8 73 -21 161 -80 243 -118
166 -377 299 -686 351 -133 23 -460 29 -565 10z m642 -279 c252 -58 423 -177
423 -296 0 -116 -128 -217 -365 -285 -183 -53 -678 -53 -860 0 -172 50 -308
134 -351 217 -49 95 20 210 171 286 55 27 194 70 284 87 109 20 596 14 698 -9z
m-132 -1866 l230 -230 -237 -237 -237 -237 -237 236 -237 236 234 231 c129
127 239 231 244 231 6 0 114 -104 240 -230z m-610 -720 c198 -198 364 -360
370 -360 6 0 172 162 370 360 l360 360 900 -2 900 -3 0 -395 0 -395 -465 -5
-465 -5 -5 -265 -5 -265 -1592 -3 -1593 -2 -2 267 -3 268 -467 3 -468 2 0 393
c0 217 3 397 7 400 3 4 409 7 902 7 l896 0 360 -360z m-1497 -1107 l-3 -398
-797 -3 -798 -2 0 400 0 400 800 0 800 0 -2 -397z m5330 0 l-3 -398 -797 -3
-798 -2 0 400 0 400 800 0 800 0 -2 -397z m-6127 -1978 c4 -60 7 -122 8 -136
1 -23 9 -28 74 -48 39 -12 113 -41 162 -65 l90 -44 95 94 95 94 95 -95 95 -95
-95 -94 -94 -95 51 -108 c28 -59 58 -134 67 -165 l17 -58 88 0 c48 0 109 -3
135 -6 l46 -7 0 -128 0 -129 -72 -1 c-40 -1 -102 -4 -136 -8 l-64 -6 -23 -73
c-13 -40 -42 -112 -65 -161 l-42 -89 33 -34 c19 -18 61 -61 93 -96 l60 -63
-89 -89 c-49 -50 -94 -90 -100 -90 -5 0 -51 41 -101 91 l-90 90 -95 -46 c-51
-25 -123 -54 -160 -65 l-66 -20 -7 -53 c-3 -28 -6 -89 -6 -134 l0 -83 -135 0
-135 0 0 134 0 134 -72 22 c-40 12 -115 42 -167 67 l-94 45 -94 -94 -93 -93
-95 95 -95 95 90 90 c49 50 90 96 90 102 0 6 -19 52 -43 100 -24 49 -52 119
-62 155 l-19 65 -90 7 c-49 3 -111 6 -138 6 l-48 0 0 130 c0 117 2 130 18 131
9 1 70 4 136 8 l119 6 24 75 c13 41 42 111 63 155 22 45 40 85 40 90 0 5 -42
51 -92 102 l-93 93 95 95 95 94 97 -95 97 -95 83 42 c46 23 119 54 163 68 l80
26 3 125 c1 69 4 131 7 138 3 9 36 12 132 10 l128 -3 6 -110z m5651 90 c43 -9
115 -36 175 -66 325 -160 490 -515 414 -887 -40 -193 -156 -359 -347 -498 -29
-21 -29 -22 -32 -138 l-4 -116 -129 0 -129 0 0 278 0 277 70 53 c128 98 185
197 185 322 0 65 -5 85 -33 142 -41 84 -128 173 -208 213 -53 27 -68 30 -154
30 -87 0 -100 -3 -157 -32 -154 -81 -255 -256 -232 -403 16 -103 79 -193 202
-287 l57 -44 0 -275 0 -274 -135 0 -135 0 0 114 0 114 -62 50 c-229 182 -328
379 -328 652 0 224 71 410 213 558 93 97 254 189 379 216 87 19 299 20 390 1z
m-123 -658 c40 -20 62 -58 63 -110 2 -97 -38 -137 -136 -137 -47 0 -61 4 -87
28 -59 53 -50 173 15 213 36 22 109 25 145 6z m331 -1382 l0 -65 -400 0 -400
0 0 65 0 65 400 0 400 0 0 -65z"
                  />
                  <path
                    d="M1490 2457 c-135 -48 -244 -140 -317 -270 -50 -87 -68 -176 -59 -286
12 -148 115 -306 259 -395 101 -62 167 -79 286 -74 106 5 154 19 237 69 78 47
149 121 200 208 53 90 67 156 62 277 -4 85 -7 99 -47 179 -118 239 -398 370
-621 292z m253 -258 c81 -22 143 -105 153 -207 13 -121 -33 -229 -118 -274
-36 -19 -58 -23 -138 -23 -79 0 -103 4 -143 24 -83 40 -121 115 -120 233 2
171 88 258 252 258 42 0 93 -5 114 -11z"
                  />
                </g>
              </svg>
            </div>
            <div class="ci-text">
              <h4>Prozessentwicklung</h4>
              <p>
                Ich bin in der Lage, in der Produktentwicklung an die
                Herstellung zu denken und in einem agilen iterativen Prozess die
                Herstellbarkeit zu optimieren.
              </p>
            </div>
          </div>
        </div>
        <div class="info-list-w-icon">
          <div class="info-block-w-icon">
            <div class="ci-icon">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-what-i-do"
                viewBox="0 0 225.000000 225.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <metadata>
                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                </metadata>
                <g
                  transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M348 2234 c-31 -16 -58 -61 -58 -96 0 -13 9 -37 20 -55 43 -71 149
-63 185 14 45 93 -56 187 -147 137z"
                  />
                  <path
                    d="M1084 2241 c-60 -26 -83 -114 -44 -164 63 -80 190 -37 190 65 0 71
-82 126 -146 99z"
                  />
                  <path
                    d="M1793 2230 c-71 -43 -63 -149 14 -185 30 -15 42 -15 75 -6 42 13 78
58 78 99 0 56 -56 112 -112 112 -13 0 -37 -9 -55 -20z"
                  />
                  <path
                    d="M728 1967 c-50 -47 -49 -118 3 -161 79 -67 201 13 169 110 -24 72
-119 100 -172 51z"
                  />
                  <path
                    d="M1387 1970 c-14 -11 -31 -36 -37 -54 -32 -97 90 -177 169 -110 53 45
51 132 -5 169 -33 22 -96 19 -127 -5z"
                  />
                  <path
                    d="M58 1944 c-31 -16 -58 -61 -58 -96 0 -13 9 -37 20 -55 43 -71 149
-63 185 14 45 93 -56 187 -147 137z"
                  />
                  <path
                    d="M360 1717 l0 -243 60 -59 60 -59 25 24 25 24 -50 51 -50 51 0 227 0
227 -35 0 -35 0 0 -243z"
                  />
                  <path
                    d="M1090 1760 l0 -200 35 0 35 0 0 200 0 200 -35 0 -35 0 0 -200z"
                  />
                  <path
                    d="M1820 1733 l0 -227 -50 -51 -50 -51 25 -24 25 -24 60 59 60 59 0 243
0 243 -35 0 -35 0 0 -227z"
                  />
                  <path
                    d="M2083 1940 c-71 -43 -63 -149 14 -185 30 -15 42 -15 75 -6 42 13 78
58 78 99 0 56 -56 112 -112 112 -13 0 -37 -9 -55 -20z"
                  />
                  <path
                    d="M70 1462 l0 -207 93 -93 c51 -50 97 -92 103 -92 6 0 19 9 29 20 18
20 17 22 -68 109 l-87 88 0 192 0 191 -35 0 -35 0 0 -208z"
                  />
                  <path
                    d="M2110 1479 l0 -190 -50 -53 -50 -53 25 -23 25 -24 60 59 60 59 0 208
0 208 -35 0 -35 0 0 -191z"
                  />
                  <path
                    d="M1055 1485 c-5 -2 -26 -6 -45 -9 -19 -4 -60 -20 -90 -38 -50 -29 -62
-31 -155 -32 -83 -1 -107 -5 -142 -24 -86 -45 -151 -123 -174 -205 -6 -23 -23
-44 -50 -63 -23 -16 -54 -51 -71 -81 -21 -38 -38 -55 -56 -59 -106 -22 -193
-89 -239 -182 -24 -48 -28 -69 -28 -137 1 -132 60 -231 178 -292 49 -26 61
-28 205 -31 l152 -4 0 35 0 36 -142 3 c-157 3 -188 12 -247 70 -71 69 -94 158
-66 254 33 113 157 196 272 180 50 -7 110 -35 137 -65 24 -26 31 -26 63 -1
l25 19 -34 32 c-35 33 -122 79 -150 79 -28 0 -21 24 19 63 72 72 173 75 247 7
39 -36 42 -37 69 -24 15 8 27 18 27 22 0 14 -74 79 -108 96 -18 10 -55 20 -83
23 -27 3 -49 8 -49 10 0 2 9 20 19 41 75 145 283 178 399 62 39 -38 67 -91 76
-142 6 -35 9 -38 42 -38 l36 0 -7 43 c-11 68 -50 142 -100 191 l-47 45 38 19
c96 48 181 36 285 -40 13 -9 24 -8 54 6 87 42 181 28 247 -35 43 -41 43 -54 0
-64 -66 -14 -141 -109 -142 -177 0 -25 3 -28 35 -28 30 0 35 3 35 23 0 29 33
82 63 102 69 45 170 18 206 -54 45 -87 9 -174 -86 -206 -32 -11 -39 -18 -41
-44 -3 -28 0 -31 23 -31 59 0 140 53 171 113 8 15 18 52 22 83 l7 55 55 -28
c56 -29 103 -83 115 -134 l6 -26 -68 0 c-82 1 -149 -26 -205 -81 -45 -43 -47
-54 -13 -76 25 -16 26 -16 52 14 39 47 98 73 164 73 125 -1 213 -91 214 -219
0 -83 -38 -147 -114 -186 -51 -27 -59 -28 -202 -28 l-149 0 0 -36 0 -36 168 4
c164 3 168 4 221 33 176 98 199 344 44 474 -31 25 -43 43 -43 61 0 44 -29 116
-64 157 -40 46 -116 90 -175 99 -29 5 -47 14 -58 31 -9 13 -38 37 -65 52 -30
17 -60 45 -77 73 -70 110 -215 165 -330 125 -43 -15 -46 -15 -85 8 -54 31
-156 52 -191 40z"
                  />
                  <path
                    d="M980 980 c-6 -19 -10 -40 -10 -47 0 -24 -20 -23 -59 2 -23 14 -44 25
-48 25 -5 0 -50 -42 -102 -94 l-93 -94 27 -47 c32 -53 28 -59 -37 -70 l-38 -7
0 -138 0 -138 51 -13 c28 -7 53 -19 56 -26 4 -9 13 -8 38 4 33 16 34 17 19 44
-11 22 -24 30 -54 35 l-40 7 0 83 0 84 44 11 c38 9 45 16 56 49 11 34 11 42
-8 72 l-21 34 55 57 c30 31 58 57 61 57 3 0 21 -9 39 -21 30 -18 37 -19 69 -8
30 11 37 20 47 56 l10 43 83 0 83 0 10 -43 c10 -36 17 -45 47 -56 32 -11 39
-10 71 10 l36 22 66 -66 67 -66 29 25 29 26 -84 84 c-46 46 -87 84 -91 84 -5
0 -26 -11 -49 -25 -39 -25 -59 -26 -59 -2 0 7 -4 28 -10 47 l-10 35 -135 0
-135 0 -10 -35z"
                  />
                  <path
                    d="M1055 789 c-90 -22 -175 -103 -205 -193 -20 -62 -9 -175 23 -229 101
-172 338 -196 468 -49 127 145 73 383 -104 457 -47 20 -133 26 -182 14z m183
-95 c64 -38 96 -92 100 -173 3 -54 -1 -74 -20 -111 -47 -91 -149 -137 -248
-111 -174 46 -219 282 -73 385 74 52 162 55 241 10z"
                  />
                  <path
                    d="M1053 630 c-124 -75 -73 -263 72 -263 113 0 181 126 120 220 -40 60
-131 81 -192 43z m126 -76 c24 -31 26 -43 10 -78 -23 -50 -105 -51 -128 0 -16
35 -14 47 10 78 15 19 30 26 54 26 24 0 39 -7 54 -26z"
                  />
                  <path
                    d="M1485 678 c-11 -6 -21 -12 -23 -13 -2 -2 0 -14 3 -28 5 -19 16 -28
51 -36 l44 -11 0 -84 0 -83 -40 -7 c-34 -5 -43 -12 -58 -42 -16 -35 -16 -37 6
-77 l22 -41 -56 -57 -57 -57 -38 24 -39 24 -39 -20 c-33 -16 -40 -26 -46 -60
l-7 -40 -82 0 -83 0 -9 42 c-7 35 -15 46 -44 60 -34 16 -36 16 -75 -7 l-40
-24 -67 67 -67 66 -26 -24 -26 -24 87 -87 87 -87 46 28 c45 28 61 30 61 7 0
-7 5 -29 11 -49 l10 -38 134 0 134 0 16 55 c11 38 20 53 28 49 7 -4 28 -18 48
-30 l36 -22 96 96 97 97 -25 40 c-27 45 -31 65 -12 65 6 0 29 5 50 11 l37 10
0 139 0 138 -39 7 c-22 3 -47 13 -58 20 -22 17 -23 17 -48 3z"
                  />
                </g>
              </svg>
            </div>
            <div class="ci-text">
              <h4>Künstliche Intelligenz</h4>
              <p>
                Mit fundierten Kenntnissen in der Künstlichen Intelligenz, bin
                ich in der Lage Probleme im Prozess frühzeitig zu erkennen um zu
                agieren und nicht nur zu reagieren.
              </p>
            </div>
          </div>
        </div>
        <div class="info-list-w-icon">
          <div class="info-block-w-icon">
            <div class="ci-icon">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-what-i-do"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <metadata>
                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                </metadata>
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M2565 4484 c-411 -69 -759 -313 -968 -679 -25 -44 -56 -86 -69 -94
-15 -9 -72 -17 -163 -22 -103 -6 -163 -15 -228 -34 -287 -83 -508 -280 -630
-561 l-42 -97 -110 -55 c-138 -70 -230 -157 -288 -272 -56 -113 -70 -183 -65
-320 4 -99 9 -126 36 -195 74 -187 232 -325 430 -376 67 -17 112 -19 419 -19
l343 0 0 -103 c0 -90 3 -110 25 -152 l24 -49 -22 -35 c-21 -35 -22 -47 -25
-331 l-3 -295 28 -55 c18 -36 43 -65 71 -85 l44 -30 472 -3 c444 -2 474 -1
495 16 32 25 33 85 3 115 -22 22 -24 22 -473 27 -431 5 -451 6 -465 24 -11 16
-14 70 -14 273 l0 254 25 24 24 25 1051 0 1051 0 24 -25 c21 -20 25 -34 25
-80 0 -47 4 -60 26 -82 21 -21 32 -24 62 -20 58 10 67 26 67 120 0 65 -4 91
-22 124 l-23 43 23 37 c18 32 23 56 26 151 l3 112 242 0 c197 0 258 4 332 19
401 83 713 400 784 797 18 103 8 327 -20 429 -87 318 -338 578 -655 678 -85
27 -222 47 -319 47 -53 0 -61 3 -90 33 -17 19 -56 74 -86 123 -78 125 -253
301 -387 388 -167 109 -349 181 -535 211 -114 18 -343 18 -453 -1z m431 -159
c350 -62 666 -280 843 -584 72 -123 148 -170 272 -171 99 0 231 -23 316 -55
255 -96 454 -325 518 -596 22 -94 20 -268 -5 -365 -61 -239 -230 -444 -455
-550 -153 -72 -177 -76 -472 -81 l-261 -5 -4 109 c-2 88 -7 116 -25 150 l-23
43 23 37 c21 36 22 49 25 318 2 191 0 293 -8 321 -15 54 -61 109 -113 137
l-42 22 -1065 3 c-778 2 -1079 0 -1119 -8 -67 -15 -143 -84 -160 -145 -7 -24
-11 -147 -11 -316 0 -273 0 -276 25 -324 l24 -49 -22 -35 c-18 -30 -22 -54
-26 -149 l-3 -113 -357 3 -356 3 -78 37 c-105 51 -186 128 -234 226 -33 66
-38 85 -41 168 -5 117 20 208 83 293 55 74 104 112 208 160 109 50 152 94 194
198 63 152 141 259 257 350 139 109 320 173 488 173 188 0 253 35 339 182 74
125 111 174 212 276 131 135 330 254 508 306 162 46 383 59 545 31z m569
-1450 l25 -24 0 -254 c0 -241 -1 -254 -20 -275 l-21 -22 -1059 0 c-1047 0
-1060 0 -1080 20 -19 19 -20 33 -20 279 l0 260 26 20 c27 21 30 21 1076 21
l1049 0 24 -25z m0 -760 l25 -24 0 -256 c0 -242 -1 -256 -20 -275 -20 -20 -33
-20 -1080 -20 -1047 0 -1060 0 -1080 20 -19 19 -20 33 -20 275 l0 256 25 24
24 25 1051 0 1051 0 24 -25z"
                  />
                  <path
                    d="M1621 2666 c-46 -25 -54 -84 -16 -121 l24 -25 381 0 c367 0 381 1
400 20 31 31 27 93 -6 119 -26 20 -37 21 -393 21 -289 -1 -371 -4 -390 -14z"
                  />
                  <path
                    d="M2685 2655 c-33 -32 -34 -83 -2 -113 21 -20 34 -22 130 -22 94 0 109
2 127 20 31 31 27 93 -6 119 -23 18 -41 21 -126 21 -92 0 -101 -2 -123 -25z"
                  />
                  <path
                    d="M3133 2670 c-45 -18 -56 -80 -22 -124 20 -25 24 -26 129 -26 122 0
145 10 156 67 5 27 1 37 -24 63 -30 29 -33 30 -124 29 -51 0 -103 -4 -115 -9z"
                  />
                  <path
                    d="M1624 1908 c-23 -11 -44 -48 -44 -77 0 -10 9 -30 21 -45 l20 -26 385
0 c371 0 385 1 404 20 31 31 27 93 -6 119 -26 20 -37 21 -393 20 -230 0 -374
-4 -387 -11z"
                  />
                  <path
                    d="M2703 1910 c-45 -19 -58 -95 -23 -130 18 -18 33 -20 130 -20 129 0
150 11 150 78 0 63 -24 77 -139 79 -53 2 -106 -2 -118 -7z"
                  />
                  <path
                    d="M3133 1910 c-45 -18 -56 -80 -22 -124 20 -25 24 -26 129 -26 122 0
145 10 156 67 5 27 1 37 -24 63 -30 29 -33 30 -124 29 -51 0 -103 -4 -115 -9z"
                  />
                  <path
                    d="M1624 1148 c-23 -11 -44 -48 -44 -77 0 -10 9 -30 21 -45 l20 -26 385
0 c371 0 385 1 404 20 28 28 27 90 -1 116 -20 18 -43 19 -393 21 -243 1 -378
-2 -392 -9z"
                  />
                  <path
                    d="M2703 1150 c-45 -19 -58 -95 -23 -130 18 -18 33 -20 130 -20 129 0
150 11 150 78 0 63 -24 77 -139 79 -53 2 -106 -2 -118 -7z"
                  />
                  <path
                    d="M3135 1148 c-49 -26 -59 -77 -24 -122 20 -25 24 -26 129 -26 90 0
111 3 131 19 34 27 32 86 -5 117 -24 22 -37 24 -119 24 -51 0 -101 -6 -112
-12z"
                  />
                  <path
                    d="M3612 957 c-17 -18 -22 -36 -22 -78 0 -101 41 -93 -508 -99 -454 -5
-481 -6 -501 -24 -28 -26 -29 -88 -1 -116 20 -20 30 -20 524 -18 l503 3 42 29
c64 45 94 103 99 197 4 73 3 79 -21 103 -34 34 -85 35 -115 3z"
                  />
                </g>
              </svg>
            </div>

            <div class="ci-text">
              <h4>Backend-Entwicklung</h4>
              <p>
                Mit fundierten Kenntnissen in PHP und relationale Datenbanken
                schaffe ich es mittels API Prozessdaten zu sammeln und zu
                verwalten.
              </p>
            </div>
          </div>
        </div>
        <div class="info-list-w-icon">
          <div class="info-block-w-icon">
            <div class="ci-icon">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-what-i-do"
                viewBox="0 0 1786.000000 1784.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <metadata>
                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                </metadata>
                <g
                  transform="translate(0.000000,1784.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M8945 15249 c-199 -18 -379 -57 -584 -126 -376 -125 -709 -331 -990
-612 -142 -141 -295 -334 -380 -478 l-30 -53 22 -14 c54 -34 182 -106 188
-106 4 0 18 19 32 43 69 116 225 315 332 422 359 356 783 572 1300 662 144 24
527 25 680 0 61 -10 126 -22 145 -27 127 -32 175 -45 254 -72 481 -160 924
-504 1196 -926 48 -75 69 -100 81 -96 18 5 179 100 192 113 4 4 -18 47 -50 97
-285 451 -733 817 -1238 1011 -133 52 -223 78 -420 123 -189 43 -502 60 -730
39z"
                  />
                  <path
                    d="M5740 13629 c-319 -25 -636 -109 -920 -247 -63 -30 -132 -66 -153
-79 -21 -13 -55 -33 -75 -44 -19 -12 -80 -52 -133 -90 -361 -256 -640 -583
-829 -974 -236 -487 -312 -1008 -225 -1540 28 -170 89 -393 136 -500 4 -11 18
-45 29 -75 12 -30 52 -114 89 -186 274 -528 723 -940 1282 -1177 274 -117 580
-185 849 -190 l135 -2 3 117 c3 138 21 124 -163 133 -230 12 -480 69 -701 160
-597 247 -1051 703 -1284 1290 -176 443 -209 969 -89 1425 187 710 689 1286
1366 1566 319 132 689 191 1030 165 86 -6 179 -16 207 -22 250 -47 384 -87
566 -168 440 -195 804 -516 1049 -923 29 -49 56 -88 61 -88 4 0 36 16 70 35
34 19 64 35 66 35 3 0 20 10 39 23 38 26 40 19 -24 126 -291 488 -768 883
-1306 1079 -350 128 -715 179 -1075 151z"
                  />
                  <path
                    d="M12300 13629 c-222 -17 -414 -57 -633 -130 -585 -197 -1085 -607
-1401 -1152 -15 -27 -24 -52 -20 -57 5 -4 36 -22 69 -40 33 -18 77 -42 98 -53
l39 -22 19 25 c10 14 19 29 19 33 0 16 128 203 196 287 243 302 549 534 904
685 451 193 966 233 1453 115 81 -19 193 -53 232 -69 11 -5 52 -21 90 -37 419
-166 809 -490 1064 -884 119 -182 239 -451 292 -650 57 -217 74 -355 74 -605
0 -213 -7 -289 -41 -460 -96 -490 -365 -950 -744 -1277 -390 -336 -878 -539
-1355 -563 -183 -9 -165 5 -165 -137 l0 -121 143 7 c617 29 1211 293 1658 736
295 292 505 631 634 1024 25 78 71 265 84 341 6 39 16 104 23 145 16 98 15
510 0 615 -43 284 -91 461 -190 700 -34 82 -141 283 -197 370 -137 213 -353
455 -535 602 -428 343 -896 530 -1443 573 -143 11 -213 11 -367 -1z"
                  />
                  <path
                    d="M8980 11429 c-91 -8 -263 -35 -320 -49 -174 -43 -255 -68 -388 -120
-702 -271 -1276 -888 -1505 -1615 -83 -264 -111 -459 -110 -770 0 -266 17
-410 74 -632 130 -510 406 -956 799 -1295 105 -91 307 -234 405 -288 338 -187
641 -285 1005 -325 888 -98 1779 291 2322 1013 361 480 542 1095 500 1695 -18
248 -53 432 -126 647 -269 806 -932 1433 -1751 1658 -291 80 -615 109 -905 81z
m490 -254 c432 -55 783 -194 1119 -445 321 -239 591 -582 746 -945 9 -22 21
-49 26 -60 16 -37 67 -199 89 -280 134 -514 70 -1117 -171 -1598 -274 -545
-759 -965 -1344 -1161 -117 -39 -131 -43 -236 -65 -227 -48 -528 -65 -733 -40
-95 11 -319 53 -371 69 -108 34 -141 46 -235 82 -681 265 -1208 862 -1389
1574 -53 209 -64 306 -64 569 -1 270 12 380 69 594 110 416 334 790 651 1091
238 225 521 396 838 507 147 51 303 85 505 112 106 14 376 12 500 -4z"
                  />
                  <path
                    d="M3820 8601 c-415 -242 -804 -573 -1116 -951 -525 -637 -852 -1420
-937 -2245 -6 -66 -12 -214 -12 -330 l0 -210 1295 -3 c712 -1 1305 0 1318 3
21 5 22 9 22 125 l0 120 -1192 2 -1193 3 -4 40 c-5 57 17 274 46 455 46 292
142 635 238 855 7 17 25 59 40 95 46 110 159 329 236 458 256 424 612 820 999
1112 85 64 296 207 336 228 19 10 40 -1 177 -92 85 -57 168 -110 183 -118 112
-59 288 -147 334 -166 372 -159 774 -252 1168 -270 83 -4 157 -5 162 -4 6 2
10 52 10 122 l0 118 -72 6 c-40 3 -116 8 -168 11 -300 18 -658 103 -955 225
-219 91 -486 244 -682 392 -54 41 -102 77 -108 80 -5 3 -62 -24 -125 -61z"
                  />
                  <path
                    d="M14378 8591 c-200 -152 -477 -312 -693 -401 -305 -125 -664 -209
-965 -225 -58 -3 -133 -8 -167 -11 l-63 -6 0 -120 0 -121 171 6 c94 4 201 11
238 17 36 6 98 15 136 21 473 71 951 264 1372 555 l93 64 37 -21 c124 -70 351
-235 510 -371 541 -463 950 -1076 1178 -1763 56 -168 96 -322 136 -525 27
-140 62 -461 57 -530 l-3 -45 -1192 -3 -1193 -2 0 -119 c0 -99 3 -120 16 -125
25 -10 2597 -7 2613 3 12 7 13 48 8 237 -5 204 -14 303 -46 534 -5 36 -21 120
-35 187 -193 920 -668 1727 -1381 2345 -141 122 -412 316 -570 408 -49 29
-104 61 -121 72 l-32 18 -104 -79z"
                  />
                  <path
                    d="M7165 6442 c-27 -16 -68 -39 -90 -51 -120 -68 -400 -266 -526 -371
-512 -429 -902 -948 -1171 -1554 -92 -208 -211 -572 -251 -766 -24 -121 -47
-248 -56 -315 -6 -44 -16 -114 -22 -155 -5 -41 -13 -184 -16 -317 l-6 -243
4183 0 4183 0 -6 233 c-17 630 -156 1188 -439 1758 -357 721 -944 1349 -1630
1744 l-117 67 -99 -75 c-159 -121 -366 -250 -507 -316 -138 -65 -180 -84 -267
-117 -365 -140 -716 -204 -1113 -204 -207 0 -294 6 -495 37 -451 67 -884 240
-1280 511 -52 36 -97 68 -100 71 -7 8 -118 91 -122 91 -2 0 -25 -13 -53 -28z
m86 -305 c192 -145 536 -328 799 -424 597 -219 1265 -262 1895 -124 184 40
407 111 575 182 190 80 510 260 649 366 24 18 48 33 53 33 23 0 289 -183 443
-305 129 -103 184 -152 330 -299 453 -455 777 -994 974 -1618 26 -82 79 -288
91 -348 31 -171 41 -229 54 -335 8 -66 18 -171 21 -232 l7 -113 -3933 0 -3932
0 7 117 c40 680 273 1367 662 1948 258 386 599 737 977 1008 128 91 259 176
273 176 6 1 31 -14 55 -32z"
                  />
                </g>
              </svg>
            </div>

            <div class="ci-text">
              <h4>Teamwork</h4>
              <p>
                Durch Kenntnisse in vielen unterschiedlichen Gebieten, schaffe
                ich es Experten auf diesen Gebieten zu führen. "Die Musiker
                spielen die Instrumente, ich spiele das Orchester!"
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'WhatIDo',
  components: {}
}
</script>
<style lang="scss" scoped>
.svg-what-i-do {
  position: relative;
  width: 70px;
  background: #f5f6f7;
  display: block;
  padding: 12px;
  border-radius: 10px;
}
</style>
