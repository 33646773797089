<template>
  <h1>Patents</h1>
</template>
<script>
export default {
  name: 'Patents',
  components: {}
}
</script>
<style lang="scss" scoped>
</style>
