<template>
  <section>
    <div class="section-content vcentered home-page-third-style">
      <div id="home_content_1" class="home-content">
        <div class="row flex-v-align">
          <div class="col-sm-12 without-padding col-md-6 col-lg-6">
            <div class="home-photo">
              <div class="hp-inner" />
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="home-text">
              <h1>
                <span class="about-me-name">Oliver Scharkowski</span>
              </h1>
              <!-- <div class="home-text-subtitle">
                <div class="owl-carousel text-rotation">
                  <div class="item">
                    <span>Maschinenbauingenieur</span>
                  </div>
                  <div class="item">
                    <span>Kenntnisse in der Digitalisierung</span>
                  </div>
                  <div class="item">
                    <span>Kenntnisse in der Künstliche Intelligenz</span>
                  </div>
                  <div class="item">
                    <span>Kenntnisse in der Frontend-Entwicklung</span>
                  </div>
                  <div class="item">
                    <span>Kenntnisse in der Backend-Entwicklung</span>
                  </div>
                </div>
              </div> -->
              <p>
                Ich bin ein Mensch der seine Welt verstehen will und sie nicht
                nur als gegeben hinnimmt, ein Mensch der davon überzeugt ist das
                der Wille stärker ist als das Wissen — denn Wissen ist zwar
                immer noch begrenzt, aber es war noch nie so einfach
                Wissenslücken zu schließen — man muss es nur wollen!<br /><br />
                Ich möchte Lösungen entwickeln und umsetzen und nicht nur über
                die Probleme reden und sie dokumentieren, deswegen fühle ich
                mich auch in einem agilen Umfeld wohl und zu Hause.<br /><br />
                Ich suche ein Umfeld wo "Think Different" gewollt ist und das
                Argument "das haben wir immer so gemacht" nur schwerer wiegt,
                wenn es tatsächlich schon das Bestmögliche ist!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'AboutMe',
  components: {}
}
</script>
<style lang="scss" scoped>
.about-me-name {
  hyphens: none !important;
}
</style>
